import {SEARCH_FILTER_ACTION} from '../actions/actionType';
import {captureError, ShowToast} from '../imports';
import {
  elasticSearchTextInDoc,
  elasticSearchFilterParams,
  elasticSearchApplyFilter,
  getFilterObj,
  checkIfValidFilterConfig,
  getSearchFilterCalculatedFooterData,
  getLabelOrSelectOptionsForFilter,
  getNoOFNonEmptyRows,
  commonBodyParamsForElastic,
  getCheckBoxOrSwitchOptionsForFilter,
} from './actionHelpers/searchFilterActionHelper';
import moment from 'moment';
import {makeRowObjFromRowDoc} from './actionHelpers/tableActionHelper';
import {
  handleCloudErrorMsgAndLogging,
  getColumnFieldType,
  getOrganisationMemberList,
} from '../utils/utils';
import {isEmpty, omit} from 'lodash';
import {
  FIELD_TYPE_ID,
  SEARCH_FILTER_MODE,
  MINI_APPS,
  ORG_MEMBER_PROFILE_FIELDS,
} from '../utils/constant';
import {
  isCustomRoleFilteredScreen,
  getHiddenColIdsForScreenMiniApps,
  getHeaderFieldMappingForElasticSearch,
} from './actionHelpers/miniAppsActionHelper';

let latestSearchCallId = 0;

const searchAsYouTypeAction = (searchStr) => async (dispatch, getState) => {
  const funtionCallId = `f_${moment().valueOf()}`;
  latestSearchCallId = funtionCallId;
  searchStr = searchStr?.trim?.();
  if (searchStr?.length > 1) {
    try {
      const {
        home: {activeDocumentId, originalDocumentId},
        auth,
        table: {headerData: headerDataArr},
        searchFilter: currentSearchFilterState,
      } = getState();

      if (
        currentSearchFilterState.isActive !== true ||
        currentSearchFilterState.mode !== SEARCH_FILTER_MODE.SEARCH
      ) {
        dispatch({
          type: SEARCH_FILTER_ACTION.CHANGE_ACTIVE_STATE,
          payload: {
            isActive: true,
            mode: SEARCH_FILTER_MODE.SEARCH,
          },
        });
      }

      if (currentSearchFilterState.isLoading !== true) {
        dispatch({
          type: SEARCH_FILTER_ACTION.UPDATE_SEARCH_FILTER_LOADING_STATE,
          payload: true,
        });
      }
      const dataObj = {
        textToSearch: searchStr,
        docId: activeDocumentId,
        originalDocumentId,
        headerIdFieldMapping:
          getHeaderFieldMappingForElasticSearch(headerDataArr),
        ...commonBodyParamsForElastic(auth),
      };
      const data = await elasticSearchTextInDoc(dataObj);

      const {
        searchFilter: {isActive},
        table: {headerData, footerData},
      } = getState();

      if (!isActive || latestSearchCallId !== funtionCallId) {
        //if search is closed or another call is made
        //do not process
        return false;
      }

      if (!data || !data.success || isEmpty(data.response)) {
        handleCloudErrorMsgAndLogging(data, dataObj, auth.userPref);
      } else {
        //success
        const tableData = data.response.tableData ?? [];
        const rowIdDataMap = data.response.rowIdDataMap;
        dispatch({
          type: SEARCH_FILTER_ACTION.UPDATE_CURRENT_SEARCHED_TEXT,
          payload: searchStr,
        });
        dispatch({
          type: SEARCH_FILTER_ACTION.UPDATE_SEARCH_FILTER_DATA,
          payload: {
            tableData,
            rowIdDataMap,
            footerData: getSearchFilterCalculatedFooterData(
              footerData,
              tableData,
              rowIdDataMap,
              headerData,
            ),
          },
        });
      }
    } catch (error) {
      captureError(error);
    }
    dispatch({
      type: SEARCH_FILTER_ACTION.UPDATE_SEARCH_FILTER_LOADING_STATE,
      payload: false,
    });
  } else {
    if (getState().searchFilter.isActive) {
      return dispatch(clearSearchFilter());
    }
  }
};

const searchInTable = (searchStr) => async (dispatch, getState) => {
  let success = false;
  searchStr = searchStr?.trim?.();
  if (searchStr?.length > 1) {
    try {
      dispatch({
        type: SEARCH_FILTER_ACTION.CHANGE_ACTIVE_STATE,
        payload: {
          isActive: true,
          mode: SEARCH_FILTER_MODE.SEARCH,
        },
      });
      dispatch({
        type: SEARCH_FILTER_ACTION.UPDATE_SEARCH_FILTER_LOADING_STATE,
        payload: true,
      });
      const {
        home: {activeDocumentId, originalDocumentId},
        auth,
        table: {headerData: headerDataArr},
      } = getState();

      const dataObj = {
        textToSearch: searchStr,
        docId: activeDocumentId,
        originalDocumentId,
        headerIdFieldMapping:
          getHeaderFieldMappingForElasticSearch(headerDataArr),
        ...commonBodyParamsForElastic(auth),
      };
      const data = await elasticSearchTextInDoc(dataObj);

      const {
        searchFilter: {isActive},
        table: {headerData, footerData},
      } = getState();

      if (!isActive) {
        //if search is closed
        //do not process
        return false;
      }

      if (!data || !data.success || isEmpty(data.response)) {
        handleCloudErrorMsgAndLogging(data, dataObj, auth.userPref);
      } else {
        //success
        success = true;
        const tableData = data.response.tableData ?? [];
        const rowIdDataMap = data.response.rowIdDataMap;
        dispatch({
          type: SEARCH_FILTER_ACTION.UPDATE_CURRENT_SEARCHED_TEXT,
          payload: searchStr,
        });
        dispatch({
          type: SEARCH_FILTER_ACTION.UPDATE_SEARCH_FILTER_DATA,
          payload: {
            tableData,
            rowIdDataMap,
            footerData: getSearchFilterCalculatedFooterData(
              footerData,
              tableData,
              rowIdDataMap,
              headerData,
            ),
          },
        });
      }
    } catch (error) {
      captureError(error);
    }
    dispatch({
      type: SEARCH_FILTER_ACTION.UPDATE_SEARCH_FILTER_LOADING_STATE,
      payload: false,
    });
  }
  return success;
};

const fetchFilterParams = (columnId) => async (dispatch, getState) => {
  try {
    const {
      home: {activeDocumentId, originalDocumentId, activeDocumentMeta},
      auth,
      table: {headerMappedValues, headerData},
      searchFilter: {filterColumnInfo},
    } = getState();

    const noOptionAvailableToast = () => {
      ShowToast('No options available for filter.', auth.userPref);
      return false;
    };

    if (filterColumnInfo[columnId]?.areOptionsAvailable) {
      if (!filterColumnInfo[columnId].options?.length) {
        return noOptionAvailableToast();
      }
      return true;
    }

    const columnObj = Object.assign(
      {},
      headerData[headerMappedValues.headerIdIndexMap?.[columnId]],
    );

    const fieldType = getColumnFieldType(columnObj);

    const dataObj = {
      columnId,
      docId: activeDocumentId,
      originalDocumentId,
      fieldType,
      collab: activeDocumentMeta.collab,
      ...commonBodyParamsForElastic(auth),
    };

    const data = [FIELD_TYPE_ID.LABEL, FIELD_TYPE_ID.SELECT_POP_UP].includes(
      fieldType,
    )
      ? getLabelOrSelectOptionsForFilter(
          columnObj,
          FIELD_TYPE_ID.LABEL === fieldType,
          false,
        )
      : await elasticSearchFilterParams(dataObj);

    if (!data || !data.success || isEmpty(data.response)) {
      handleCloudErrorMsgAndLogging(data, dataObj, auth.userPref);
      return false;
    } else {
      //success
      const {searchFilter} = getState();
      const options = data.response.valsArr ?? [];
      const updatedFilterColObj = getFilterObj(
        searchFilter.filterColumnInfo,
        columnId,
        {
          areOptionsAvailable: true,
          options,
        },
      );
      dispatch({
        type: SEARCH_FILTER_ACTION.UPDATE_FILTER_COLUMN_INFO,
        payload: updatedFilterColObj,
      });
      if (!options.length) {
        return noOptionAvailableToast();
      }
      return true;
    }
  } catch (error) {
    captureError(error);
  }
};

const fetchFilterParamsForDoc =
  (
    docId,
    hiddenColIds = null,
    applyMiniAppScreenRestrictions = false,
    orgMemberList = [],
    screenId = undefined,
    searchText = '',
    headerIdFieldMappingActiveQf = {},
    resultSize = null,
  ) =>
  async (_, getState) => {
    try {
      const {auth} = getState();

      hiddenColIds = Array.isArray(hiddenColIds)
        ? hiddenColIds
        : getHiddenColIdsForScreenMiniApps(screenId);

      const dataObj = {
        docId,
        isAllColumns: true,
        resultSize,
        ...commonBodyParamsForElastic(auth),
      };

      if (Array.isArray(hiddenColIds) && hiddenColIds.length) {
        Object.assign(dataObj, {columnIdsToExclude: hiddenColIds});
      }

      if (searchText?.trim() && !isEmpty(headerIdFieldMappingActiveQf)) {
        Object.assign(dataObj, {
          textToSearch: searchText.trim(),
          headerIdFieldMapping: headerIdFieldMappingActiveQf,
          resultSize: null,
        });
      }

      if (applyMiniAppScreenRestrictions) {
        const commonParams = (() => {
          const params = {};
          try {
            const {
              miniApps: {
                miniApps,
                activeAppId,
                activeScreenId,
                activeCustomRoleInfo,
              },
            } = getState();
            if (activeAppId && activeScreenId) {
              //any mini app screen is open
              const currScreenMeta =
                miniApps[activeAppId]?.screens?.[activeScreenId];

              params.filters =
                currScreenMeta?.type === MINI_APPS.SCREEN_TYPE.FILTERED_DATA
                  ? currScreenMeta.docs[0].filterOptions
                  : [];

              const [isCustomViewAccess, viewAccess] =
                isCustomRoleFilteredScreen(
                  miniApps[activeAppId],
                  auth.user?.uid,
                  activeScreenId,
                  activeCustomRoleInfo,
                );
              if (isCustomViewAccess) {
                Object.assign(params, {viewAccess});
              }
            }
          } catch (err) {
            captureError(err);
          }
          return params;
        })();
        Object.assign(dataObj, commonParams);
      }

      const data = await elasticSearchFilterParams(dataObj);

      if (!data || !data.success || isEmpty(data.response)) {
        handleCloudErrorMsgAndLogging(data, dataObj, auth.userPref);
        return null;
      } else {
        //success
        const selectBoxColObj = [];
        const labelColObj = [];
        const assignTaskObj = [];
        let assignTaskMembers = [];
        const createdInfoColObj = [];
        const createInfoOptions = [];

        if (Array.isArray(orgMemberList) && orgMemberList.length) {
          assignTaskMembers = orgMemberList.map(
            (obj) => obj.name ?? obj.m_name ?? obj.contact,
          );
          orgMemberList.forEach((member) => {
            Object.assign(createInfoOptions, [
              ...createInfoOptions,
              member.name ?? member.m_name ?? member.contact,
            ]);
          });
        }
        const headerData = (data.response.headerData ?? []).filter((colObj) => {
          //! As we have not saved data of below fieldTypes in Table Column => we can't consider subType
          //const fieldType = getColumnFieldType(colObj);
          switch (colObj.fieldType) {
            case FIELD_TYPE_ID.SELECT_POP_UP: {
              return selectBoxColObj.push(colObj);
            }
            case FIELD_TYPE_ID.LABEL: {
              return labelColObj.push(colObj);
            }
            case FIELD_TYPE_ID.ASSIGN_TASK: {
              return assignTaskObj.push(colObj);
            }
            case FIELD_TYPE_ID.CREATED_INFO: {
              return createdInfoColObj.push(colObj);
            }
          }

          //! We are hiding subType LABEL since we are not getting appropriate filter options
          //! from elastic and it is causing a CRASH
          //! Once this issue is resolved, we need to remove the below check
          if (
            colObj?.fieldType === FIELD_TYPE_ID.TABLE &&
            colObj?.subType === FIELD_TYPE_ID.LABEL
          ) {
            return false;
          }

          return !hiddenColIds.includes(colObj.id);
        });
        const filterCols = Object.assign(
          {},
          data.response.filterColIdOptionsMapping,
          selectBoxColObj.reduce(
            (acc, colObj) =>
              Object.assign({}, acc, {
                [colObj.id]: getLabelOrSelectOptionsForFilter(
                  colObj,
                  false,
                  true,
                  searchText,
                ),
              }),
            {},
          ),
          labelColObj.reduce(
            (acc, colObj) =>
              Object.assign({}, acc, {
                [colObj.id]: getLabelOrSelectOptionsForFilter(
                  colObj,
                  true,
                  true,
                  searchText,
                ),
              }),
            {},
          ),
          assignTaskObj.reduce(
            (acc, colObj) =>
              assignTaskMembers.length
                ? Object.assign({}, acc, {
                    [colObj.id]: assignTaskMembers,
                  })
                : {},
            {},
          ),
          createdInfoColObj.reduce(
            (acc, colObj) =>
              createInfoOptions.length
                ? Object.assign({}, acc, {
                    [colObj.id]: createInfoOptions,
                  })
                : {},
            {},
          ),
        );
        return {
          headerData,
          filterColIdOptionsMapping: filterCols,
        };
      }
    } catch (error) {
      captureError(error);
      return null;
    }
  };

const getFieldType = (colId, headerMappedValues, headerData) => {
  return getColumnFieldType(
    headerData[headerMappedValues.headerIdIndexMap?.[colId]],
  );
};

const applyFilterAction =
  ({
    filterArr = [],
    isRemove = false,
    isAppendToExistingFilters = true,
    isCampaignFilter = false,
  }) =>
  async (dispatch, getState) => {
    try {
      if (
        !filterArr?.length ||
        filterArr.some(
          (filterObj) =>
            !filterObj?.columnId ||
            (!isRemove && !filterObj.selectedOptions.length),
        )
      ) {
        return;
      }

      const {
        home: {activeDocumentId, originalDocumentId, activeDocumentMeta},
        auth,
        table: {headerMappedValues, headerData},
        searchFilter: {filterColumnInfo},
        campaigns: {docDetails, campaignColDetails},
      } = getState();

      if (!isRemove) {
        let isValidFilterConfig = true;
        filterArr.forEach(({columnId, selectedOptions}, index) => {
          const res = checkIfValidFilterConfig(
            getFieldType(columnId, headerMappedValues, headerData),
            selectedOptions,
            auth.userPref,
            isValidFilterConfig,
          );
          isValidFilterConfig = isValidFilterConfig && Boolean(res[0]);
          filterArr[index].selectedOptions = res[1];
        });
        if (!isValidFilterConfig) {
          return;
        }
      }

      const updatedFilterColObj = {};
      if (isRemove) {
        Object.assign(
          updatedFilterColObj,
          omit(
            filterColumnInfo,
            filterArr.map((filterObj) => filterObj.columnId),
          ),
        );
      } else {
        Object.assign(
          updatedFilterColObj,
          filterArr.reduce(
            (obj, {columnId, selectedOptions}) => {
              return getFilterObj(obj, columnId, {
                isActive: true,
                selectedOptions,
              });
            },
            isAppendToExistingFilters ? filterColumnInfo : {},
          ),
        );
      }
      const filters = Object.keys(updatedFilterColObj)
        .filter((colId) => updatedFilterColObj[colId]?.isActive)
        .map((colId) =>
          Object.assign(
            {},
            {colId},
            {selectedOptions: updatedFilterColObj[colId].selectedOptions},
            {fieldType: getFieldType(colId, headerMappedValues, headerData)},
          ),
        );

      if (!filters.length) {
        dispatch(clearSearchFilter());
        return true;
      }
      const dataObj = {
        docId: isCampaignFilter ? docDetails?.docId : activeDocumentId,
        originalDocumentId: isCampaignFilter
          ? docDetails?.docId
          : originalDocumentId,
        filters,
        collab: activeDocumentMeta.collab,
        ...commonBodyParamsForElastic(auth),
        isCampaign: isCampaignFilter,
        ...(isCampaignFilter
          ? {
              nonEmptyColIds: [
                {
                  colId: campaignColDetails.id,
                  fieldType: campaignColDetails.fieldType,
                },
              ],
            }
          : {}),
        isPaginated: false,
      };

      const data = await elasticSearchApplyFilter(dataObj);

      if (!data || !data.success || isEmpty(data.response)) {
        handleCloudErrorMsgAndLogging(data, dataObj, auth.userPref);
        return false;
      } else {
        //success
        const {
          searchFilter: {isActive},
          table: {headerData: actualHeaderData, footerData},
        } = getState();

        if (!isActive) {
          dispatch({
            type: SEARCH_FILTER_ACTION.CHANGE_ACTIVE_STATE,
            payload: {
              isActive: true,
              mode: SEARCH_FILTER_MODE.FILTER,
            },
          });
        }
        dispatch({
          type: SEARCH_FILTER_ACTION.UPDATE_FILTER_COLUMN_INFO,
          payload: updatedFilterColObj,
        });
        const tableData = data.response.tableData ?? [];
        const rowIdDataMap = data.response.rowIdDataMap;
        dispatch({
          type: SEARCH_FILTER_ACTION.UPDATE_SEARCH_FILTER_DATA,
          payload: {
            tableData,
            rowIdDataMap,
            footerData: getSearchFilterCalculatedFooterData(
              footerData,
              tableData,
              rowIdDataMap,
              actualHeaderData,
            ),
          },
        });
        return true;
      }
    } catch (error) {
      captureError(error);
      return false;
    }
  };

const filterNonEmptyRows = () => async (dispatch, getState) => {
  try {
    const {
      campaigns: {docDetails, campaignColDetails},
      auth,
    } = getState();

    const response = await getNoOFNonEmptyRows({
      docId: docDetails?.docId,
      filters: [
        {colId: campaignColDetails.id, fieldType: campaignColDetails.fieldType},
      ],
      ...commonBodyParamsForElastic(auth),
    });

    if (response.success && response?.response?.rowsCount) {
      dispatch({
        type: SEARCH_FILTER_ACTION.UPDATE_NON_EMPTY_ROW_COUNT,
        payload: response?.response?.rowsCount,
      });
    }
  } catch (error) {
    captureError(error);
  }
};

const clearSearchFilter = () => (dispatch) =>
  dispatch({type: SEARCH_FILTER_ACTION.CLEAR_SEARCH_FILTER});

const updateSearchFilterTableData =
  (rowDataArr, isNotProcessed = false) =>
  async (dispatch, getState) => {
    try {
      const {
        searchFilter: {
          isActive,
          searchFilterTableData,
          searchFilterRowIdDataMap,
        },
        table: {headerData, footerData},
      } = getState();
      if (isActive) {
        const updateObj = {};
        const omitArr = [];
        rowDataArr.forEach((doc) => {
          const [rowData, isDeleted] = isNotProcessed
            ? makeRowObjFromRowDoc(doc)
            : [doc, false];
          const rowId = rowData?.rowId;
          if (rowId && rowId in searchFilterRowIdDataMap) {
            if (isDeleted) {
              omitArr.push(rowId);
            } else {
              updateObj[rowId] = rowData;
            }
          }
        });
        if (!isEmpty(updateObj) || omitArr.length) {
          const updatedSearchFilterTableData = omitArr.length
            ? searchFilterTableData.filter((rowId) => !omitArr.includes(rowId))
            : searchFilterTableData;

          const tableData = updatedSearchFilterTableData;
          const rowIdDataMap = omit(
            Object.assign({}, searchFilterRowIdDataMap, updateObj),
            omitArr,
          );
          dispatch({
            type: SEARCH_FILTER_ACTION.UPDATE_SEARCH_FILTER_DATA,
            payload: {
              tableData,
              rowIdDataMap,
              footerData: getSearchFilterCalculatedFooterData(
                footerData,
                tableData,
                rowIdDataMap,
                headerData,
              ),
            },
          });
        }
      }
    } catch (error) {
      captureError(error);
    }
  };

const getOrgMemberListWithSearch = (textToSearch = '') => {
  const orgMemberList = getOrganisationMemberList();
  if (!textToSearch) return orgMemberList;

  return orgMemberList?.filter((orgMember) => {
    const name = orgMember[ORG_MEMBER_PROFILE_FIELDS.NAME]?.toLowerCase();
    const contact = orgMember[ORG_MEMBER_PROFILE_FIELDS.PHONE]?.toLowerCase();
    const email = orgMember[ORG_MEMBER_PROFILE_FIELDS.EMAIL]?.toLowerCase();

    if (
      [name, contact, email].some((orgField) =>
        orgField.includes(textToSearch.trim().toLowerCase()),
      )
    ) {
      return true;
    }
    return false;
  });
};

// Fetch quick filter params for active screen
const fetchQuickFilterParamsForScreen =
  (activeQfColId, searchText) => async (dispatch, getState) => {
    try {
      const {
        miniApps: {miniApps, activeAppId, activeScreenId, docsData},
      } = getState();
      const docId =
        miniApps[activeAppId]?.screens[activeScreenId]?.docs[0]?.docId;

      if (!activeAppId || !activeScreenId) {
        throw new Error('Invalid appId or screenId');
      }

      if (!docId) {
        throw new Error('Doc for screen does not exist');
      }

      const headerDataForScreenDoc = docsData[docId]?.headerData ?? [];

      const quickFilterColumns = activeQfColId
        ? [activeQfColId]
        : miniApps[activeAppId].screens[activeScreenId]?.quickFilterColumns ??
          [];
      const headerDataForQuickFilterColumns = (
        headerDataForScreenDoc ?? []
      ).filter((colObj) => quickFilterColumns.includes(colObj.id));

      let headerIdFieldMappingActiveQf = {};
      const activeQFColObj =
        docsData?.[docId]?.headerDataAsObj?.[activeQfColId];

      if (!isEmpty(activeQFColObj)) {
        headerIdFieldMappingActiveQf = getHeaderFieldMappingForElasticSearch([
          activeQFColObj,
        ]);
      }

      /**
       * fieldTypes => for which fetch from elastic not needed
       * (LABEL,SELECT_POPUP,CHECKBOX,SWITCH,OTP)
       * (due to limited available options / values can be retrieved from col properties)
       */

      const selectBoxColObjArray = [];
      const labelColObjArray = [];
      const checkBoxColObjArray = [];
      const switchColObjArray = [];
      const assignTaskColObjArray = [];
      const otpColObjArray = [];
      headerDataForQuickFilterColumns.forEach((colObj) => {
        const fieldType = getColumnFieldType(colObj);
        if (
          colObj.fieldType === FIELD_TYPE_ID.TABLE &&
          [FIELD_TYPE_ID.LABEL, FIELD_TYPE_ID.SELECT_POP_UP].includes(fieldType)
        ) {
          return;
        }

        switch (fieldType) {
          case FIELD_TYPE_ID.SELECT_POP_UP: {
            return selectBoxColObjArray.push(colObj);
          }
          case FIELD_TYPE_ID.LABEL: {
            return labelColObjArray.push(colObj);
          }
          case FIELD_TYPE_ID.CHECKBOX: {
            return checkBoxColObjArray.push(colObj);
          }
          case FIELD_TYPE_ID.SWITCH: {
            return switchColObjArray.push(colObj);
          }
          case FIELD_TYPE_ID.ASSIGN_TASK: {
            return assignTaskColObjArray.push(colObj);
          }
          case FIELD_TYPE_ID.OTP: {
            return otpColObjArray.push(colObj);
          }
        }
      });

      let cfResponse = {};

      // If quick filter applied only for filterOptionsFromHeaderDataCols columns, don't make a call to cf
      if (
        (headerDataForQuickFilterColumns ?? []).length !==
        switchColObjArray.length +
          labelColObjArray.length +
          checkBoxColObjArray.length +
          selectBoxColObjArray.length +
          assignTaskColObjArray.length +
          otpColObjArray.length
      ) {
        // Contains colIds for which filterOptions need not be fetched from elastic
        const colsExcludingSelectedQuickFilterColumns = headerDataForScreenDoc
          .filter((item) => !quickFilterColumns.includes(item.id))
          .map((item) => item.id);

        cfResponse = await dispatch(
          fetchFilterParamsForDoc(
            docId,
            colsExcludingSelectedQuickFilterColumns,
            true,
            [],
            null,
            searchText,
            headerIdFieldMappingActiveQf,
            1000,
          ),
        );
      }

      const filterColIdOptionsMapping = Object.assign(
        {},
        cfResponse?.filterColIdOptionsMapping ?? {},
        labelColObjArray.reduce(
          (acc, colObj) =>
            Object.assign({}, acc, {
              [colObj.id]: getLabelOrSelectOptionsForFilter(
                colObj,
                true,
                true,
                searchText,
              ),
            }),
          {},
        ),
        selectBoxColObjArray.reduce(
          (acc, colObj) =>
            Object.assign({}, acc, {
              [colObj.id]: getLabelOrSelectOptionsForFilter(
                colObj,
                false,
                true,
                searchText,
              ),
            }),
          {},
        ),
        checkBoxColObjArray.reduce(
          (acc, colObj) =>
            Object.assign({}, acc, {
              [colObj.id]: getCheckBoxOrSwitchOptionsForFilter(colObj, true),
            }),
          {},
        ),
        switchColObjArray.reduce(
          (acc, colObj) =>
            Object.assign({}, acc, {
              [colObj.id]: getCheckBoxOrSwitchOptionsForFilter(colObj, true),
            }),
          {},
        ),
        assignTaskColObjArray.reduce(
          (acc, colObj) =>
            Object.assign({}, acc, {
              [colObj.id]: getOrgMemberListWithSearch(searchText),
            }),
          {},
        ),
        otpColObjArray.reduce(
          (acc, colObj) =>
            Object.assign({}, acc, {
              [colObj.id]: getCheckBoxOrSwitchOptionsForFilter(colObj, true),
            }),
          {},
        ),
      );
      return {
        headerData: headerDataForQuickFilterColumns,
        filterColIdOptionsMapping,
      };
    } catch (error) {
      captureError(error);
    }
    return {};
  };

export {
  searchInTable,
  clearSearchFilter,
  fetchFilterParams,
  updateSearchFilterTableData,
  applyFilterAction,
  filterNonEmptyRows,
  fetchFilterParamsForDoc,
  searchAsYouTypeAction,
  fetchQuickFilterParamsForScreen,
};
