import {
  captureError,
  captureInfo,
  firestore,
  getReduxState,
  functions,
  ENV,
} from '../../imports';
import {
  getLocalText,
  convertCellDataToText,
  checkCellContainsImage,
  checkIfValueExistOnCell,
  isQuickFilterableColumn,
  callCloudFunction,
  handleCloudErrorMsgAndLogging,
  handleCloudError,
  getColumnFieldType,
  getFiltersArrFromSelectedOptionsObj,
  getLanguageText,
  // getHeaderDataAsObjWithoutBackgroundFields,
  // isBackgroundField,
  //getPdfConfigFireStore,
} from '../../utils/utils';
import {
  CLOUD_FUNCTION_PATHS,
  MINI_APPS,
  FIELD_TYPE_ID,
  TOTAL_NOT_ALLOWED,
  SPLIT_BY_ALLOWED_FIELDS,
  OPTION_COLUMN_FILTER,
  CLOUD_FUNCTION_COMMON_PARAMS,
  COLUMN_PROPERTY_KEYS,
  MINI_APPS_FIELDS,
  OTHERS_SECTION_ID,
  FIELD_OPERATION_TYPE,
} from '../../utils/constant';
import {getOrganisationUserName} from '../../utils/organistionUtils.js';
import {getContactInfo} from './contactActionHelper';
import {forOwn, get, has, isEmpty, isNil, omit, pick} from 'lodash';
import LioArray from '../../utils/CustomClass/LioArray';
import {
  commonBodyParamsForElastic,
  mapFilterArrForStoringFirestore,
  mapFilterArrFromFirestore,
} from './searchFilterActionHelper';
import {getActionButtonsArrayForScreen} from './actionBtnHelper';
import moment from 'moment';
import {MINI_APP_INTEGRATION_PLATFORMS} from '../../utils/miniAppConstants';
import {
  getMiniAppPaginatedDocData,
  startSearchFilterOnMiniAppScreen,
  updateScreenLocalConfig,
} from '../miniAppsAction.js';
import {processRowsDataForMappedRowId} from './tableActionHelper.js';
import ColumnUtility from '../../utils/ColumnUtility.js';

const FILTER_ONLY_DATA = {
  isFilteredDataLoading: true,
  isUpdateAvailable: false,
  isFailed: false,
  tableData: [],
};
// below states are under filterData[screenId] object
// isFilteredDataLoading: true, //start loading filters
// isUpdateAvailable: false, //update for FilteredData data might be available
// isFailed: false, //fetching FilteredData failed due to some issue
// tableData: [], //filtered data

const DEFAULT_DOC_DATA = {
  tableData: [],
  headerData: [],
  rowIdDataMap: {},
  headerDataAsObj: {},
  fileObj: {},
  noOfRows: null,
  isLoading: true, //start loading
  lastFetchedTimestamp: null,
  areAllRowsFetched: false,
  isLoadingMoreRows: false,
  docExists: true,
};

const DEFAULT_SEARCH_FILTER_DATA = {
  tableData: [],
  isLoading: true,
  searchedText: '',
  filterOptions: {},
  isActive: false,
  areAllRowsFetched: false,
  isLoadingMoreRows: false,
  hitsSize: 0,
};

const addEditMiniAppEntry = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.ADD_EDIT_MINI_APP_ENTRY, obj);

const addEditFileObjPropertiesCF = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.ADD_EDIT_FILE_OBJ_PROPERTIES, obj);

const changeMiniAppProperty = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.CHANGE_MINI_APP_PROPERTY, obj);

const createEditMiniAppCloudFunction = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.CREATE_OR_UPDATE_MINI_APPS, obj);

const addEditScreenMiniAppCloudFunction = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.ADD_OR_EDIT_SCREEN_MINI_APPS, obj);

const addEditMultipleScreenMiniAppCloudFunction = (obj) =>
  callCloudFunction(
    CLOUD_FUNCTION_PATHS.ADD_OR_EDIT_MULTIPLE_SCREEN_MINI_APPS,
    obj,
  );

const duplicateScreenMiniAppCloudFunction = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.DUPLICATE_MINI_APP_SCREEN, obj);

const manageScreenMiniAppsCloudFunction = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.MANAGE_SCREENS_MINI_APPS, obj);

const searchFilterOnMiniAppScreenCloudFunction = (obj) =>
  callCloudFunction(
    CLOUD_FUNCTION_PATHS.MINI_APPS_SEARCH_FILTER_DATA,
    obj,
    null,
    CLOUD_FUNCTION_COMMON_PARAMS.ELASTIC_REQUEST,
  );

const shareChangePermissionMiniAppCloud = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.SHARE_MINI_APP, obj);

const removeSharedUserOrLeaveAppCloud = (obj) =>
  callCloudFunction(
    CLOUD_FUNCTION_PATHS.REMOVE_SHARED_USER_OR_LEAVE_MINI_APP,
    obj,
  );

const createEditMiniAppCustomRolesCloud = (obj) =>
  callCloudFunction(
    CLOUD_FUNCTION_PATHS.CREATE_EDIT_MINI_APP_CUSTOM_ROLES,
    obj,
  );

const createAppFromAppStoreCloudFunction = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.INSTALL_MINI_APP_FROM_APP_STORE, obj, {
    timeout: 500000,
  });

const deleteSampleDataFromMiniApps = async (appId) => {
  try {
    const functionInstance = functions().httpsCallable(
      CLOUD_FUNCTION_PATHS.DELETE_MINI_APPS_SAMPLE_DATA,
    );
    const response = await functionInstance({appId});
    return response?.data;
  } catch (error) {
    return handleCloudError(error);
  }
};

export const miniAppsGenerateReport = (obj) =>
  callCloudFunction(
    CLOUD_FUNCTION_PATHS.GENERATE_MANUALLY_MINI_APP_REPORT,
    obj,
    null,
    CLOUD_FUNCTION_COMMON_PARAMS.ELASTIC_REQUEST,
  );

const deleteRowCF = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.DELETE_ROW_CF, obj);

const deleteRowV2CF = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.DELETE_ROW_V2, obj, {timeout: 300000});

export const exportMiniAppScreenDataAsExcelCloud = (obj) =>
  callCloudFunction(
    CLOUD_FUNCTION_PATHS.EXPORT_MINI_APP_SCREEN_DATA_AS_EXCEL,
    obj,
  );

export const triggerActionBtnByMultiSelectCloud = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.TRIGGER_ACTION_BTN_MULTI_SELECT, obj, {
    timeout: 300000,
  });

const importExcelForMiniAppCloudFunction = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.IMPORT_EXCEL_FOR_MINI_APP, obj, {
    timeout: 540000,
  });

const createImportTemplateCloudFunction = (obj) =>
  callCloudFunction(
    CLOUD_FUNCTION_PATHS.CREATE_IMPORT_TEMPLATES_FOR_MINI_APPS,
    obj,
  );
const createExportTemplateCloudFunction = (obj) =>
  callCloudFunction(
    CLOUD_FUNCTION_PATHS.CREATE_EXPORT_TEMPLATES_FOR_MINI_APPS,
    obj,
  );

const exportOrgInfoDataCloudFunction = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.EXPORT_ORG_INFO_DATA, obj);

const deleteImportedRowsForMiniAppCloudFunction = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.DELETE_IMPORTED_ROWS, obj, {
    timeout: 540000,
  });

const sortDocByCustomColumnIdCloudFunction = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.SORT_DOC_BY_COLUMN, obj, {
    timeout: 540000,
  });
const addFileToTemplateList = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.UPDATE_CREATED_IMPORT_TEMPLATE, obj);

const addEditActionButtonsCloudFunction = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.ADD_EDIT_ACTION_BUTTONS, obj);

const getHeaderForImportExcelFile = (appId, fileName, originalFileName) => {
  const reduxState = getReduxState();
  const mappedScreenStates = mapMiniAppStates(reduxState, false, true);
  const headerData = mappedScreenStates?.documentData?.headerData ?? [];
  const reqParams = {
    appId,
    screenId: mappedScreenStates.activeScreenId,
    fileName,
    headerToMap: headerData,
    originalFileName,
  };
  return callCloudFunction(
    CLOUD_FUNCTION_PATHS.IMPORT_EXCEL_GET_HEADER_MAPPING_CONFIG,
    reqParams,
  ).then((res) => {
    if (!res?.success || !res.headers) {
      handleCloudErrorMsgAndLogging(
        res,
        reqParams,
        getReduxState().auth.userPref,
        true,
      );
      return null;
    }
    return [res.headers, res.preselectedHeaderMapping];
  });
};

const processImportExcelDocFirestore = (
  doc,
  user,
  profileData,
  membersList,
) => {
  const actualData = doc.data();
  const failedRowsCount = actualData.failedRowsCount ?? 0;
  const successRowsCount = actualData.successRowsCount ?? 0;
  const mergedRowsCount = actualData.mergedRowsCount ?? 0;
  const addedByName =
    getOrganisationUserName(
      user.uid,
      profileData?.ownerUID,
      profileData?.owner,
      membersList,
    ) ?? '';
  const processedData = omit(
    Object.assign({}, actualData, {
      failedRowsCount: failedRowsCount - mergedRowsCount, //failed rows
      newRowsCount: successRowsCount, //new rows
      mergedRowsCount, //updated rows
      isCompleted: Boolean(actualData.isCompleted),
      addedByName,
    }),
    ['successRowsCount'],
  );
  return Object.assign({}, processedData, {docId: doc.id});
};

const getHeaderFieldMappingForElasticSearch = (headerData) =>
  (headerData ?? []).reduce(
    (acc, curr) =>
      Object.assign({}, acc, {
        [curr.id]: pick(curr, ['fieldType', 'subType', 'dateFormat']),
      }),
    {},
  );

const getMiniAppsFormattedGroups = (groups = {}, screens = {}) => {
  if (Array.isArray(groups)) return [...groups];
  const screenDataWithGroup = Object.assign({}, groups, screens);
  const sortedGroup = new LioArray();
  forOwn(screenDataWithGroup, (groupData, id) => {
    const isScreen =
      has(screens, [id]) &&
      (!has(screens[id], 'groupId') || isNil(screens[id]?.groupId));
    const {groupId} = groupData;

    if (isScreen) {
      sortedGroup.binaryInsert(
        Object.assign(
          {},
          {screenId: id, sortingIndex: screens?.[id].sortingIndex},
        ),
        ['sortingIndex'],
        'duplicate',
      );
    } else if (groupId && has(groupData, 'screenIds')) {
      const updatedGroupData = Object.assign({}, groupData);
      sortedGroup.binaryInsert(
        Object.assign({}, updatedGroupData, {groupId: id}),
        ['sortingIndex'],
        'duplicate',
        false,
      );
    }
  });
  return [...sortedGroup];
};

const getScreenCustomLayoutTypeAndView = (screenData = {}) => {
  if (isEmpty(screenData)) {
    return {};
  }

  const calendarViewType = MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.CALENDAR;
  const gridViewType = MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.GRID;
  const defaultViewType = MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.DEFAULT;
  const kanbanViewType = MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN;

  const {customLayoutType, view} = Object.assign({}, screenData);
  const {type, fullScreenCalendarMapping, layoutSubtype} = Object.assign(
    {},
    view,
  );

  const isHalfCalendarWithGridView =
    [calendarViewType, gridViewType, null, undefined, 'LIST'].includes(
      customLayoutType,
    ) &&
    type === calendarViewType &&
    isEmpty(fullScreenCalendarMapping) &&
    [null, undefined, gridViewType].includes(layoutSubtype);

  const isHalfCalendarWithDefaultView =
    [calendarViewType, defaultViewType, null, undefined].includes(
      customLayoutType,
    ) &&
    type === calendarViewType &&
    isEmpty(fullScreenCalendarMapping) &&
    [null, undefined, defaultViewType].includes(layoutSubtype);

  const isFullCalendarView =
    !isEmpty(fullScreenCalendarMapping) &&
    [calendarViewType, null, undefined, 'LIST'].includes(customLayoutType);

  const isDefaultView = [null, undefined, defaultViewType].includes(
    customLayoutType,
  );
  const isKanbanView = customLayoutType === kanbanViewType;

  const screenView = Object.assign({}, view);

  if (isHalfCalendarWithDefaultView) {
    return {
      customLayoutType: calendarViewType,
      view: Object.assign({}, screenView, {
        layoutSubtype: defaultViewType,
      }),
    };
  } else if (isHalfCalendarWithGridView) {
    return {
      customLayoutType: calendarViewType,
      view: Object.assign({}, screenView, {
        layoutSubtype: gridViewType,
      }),
    };
  } else if (isFullCalendarView) {
    return {
      customLayoutType: calendarViewType,
      view: Object.assign({}, screenView),
    };
  } else if (isDefaultView) {
    return {
      customLayoutType: defaultViewType,
      view: Object.assign({}, screenView),
    };
  } else if (isKanbanView) {
    return {
      customLayoutType: kanbanViewType,
      view: Object.assign({}, screenView),
    };
  }
  return {view, customLayoutType};
};

const getFormattedMiniAppObj = (miniAppObj, miniAppId, userUID, userPref) => {
  try {
    const miniAppIntegrations = Object.assign({}, miniAppObj?.integrations);
    const isMiniAppOwner = miniAppObj?.appOwner?.uid === userUID;
    const userPermission = miniAppObj?.sharedWith?.[userUID]?.permission;
    const docData = Object.assign({}, miniAppObj, {
      miniAppId,
      isMiniAppOwner,
    });
    //convert appUsers object to array
    const contacts = [];
    const appUsersArr = Object.keys(Object.assign({}, docData.appUsers)).map(
      (uid) => {
        contacts.push(docData.appUsers[uid]?.contact);
        return Object.assign({}, docData.appUsers[uid], {uid});
      },
    );
    const mappedContactArr = getContactInfo(contacts, false);
    const appUsers = appUsersArr.map((obj, index) => {
      const isSelf = obj.uid === userUID;
      return Object.assign(
        {},
        obj,
        isSelf ? {name: getLocalText(userPref, 'Me')} : null,
        mappedContactArr[index]?.isPresent &&
          mappedContactArr[index].info?.hasThumbnail
          ? {
              hasThumbnail: true,
              thumbnailPath: mappedContactArr[index].info.thumbnailPath,
            }
          : null,
      );
    });
    const screens = {};
    forOwn(docData.screens, (screenData, screenId) => {
      const docs = Object.keys(Object.assign({}, screenData.docs));
      const isFilteredDataScreen =
        screenData.type === MINI_APPS.SCREEN_TYPE.FILTERED_DATA;
      const isDocBasedScreen = MINI_APPS.DOC_BASED_SCREEN_TYPES.includes(
        screenData.type,
      );

      // to set the keys : "customLayoutType" , "view" for old users
      const {customLayoutType, view} =
        getScreenCustomLayoutTypeAndView(screenData);
      screens[screenId] = Object.assign(
        {},
        screenData,
        {
          docs: docs.map((docId) => {
            const docMetaForScreen = screenData.docs[docId];
            return Object.assign(
              {},
              docMetaForScreen,
              {docId},
              isFilteredDataScreen
                ? {
                    filterOptions: mapFilterArrFromFirestore(
                      docMetaForScreen.filterOptions,
                    ),
                  }
                : null,
            );
          }),
          isHiddenForMe:
            Array.isArray(screenData.hideFor) &&
            screenData.hideFor.includes(userUID),
        },
        isDocBasedScreen
          ? {
              actionBtns:
                userPermission !==
                MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.VIEW
                  ? getActionButtonsArrayForScreen(miniAppObj, screenId)
                  : [],
              customLayoutType,
              view,
            }
          : null,
      );
    });
    const currGroups = Object.assign({}, docData.groups);
    const currScreens = Object.assign({}, docData.screens);
    const groups = getMiniAppsFormattedGroups(currGroups, currScreens);

    docData.appLanguageText = miniAppObj.appLanguageText || {};

    groups.forEach(
      (group, index) =>
        (groups[index][`isHiddenForMe`] =
          Array.isArray(group?.hideFor) && group?.hideFor.includes(userUID)),
    ); // handles such groups (and children screens) which are hidden by user

    return Object.assign({}, docData, {
      appUsers,
      screens,
      groups,
      miniAppIntegrations,
    });
  } catch (error) {
    captureError(error);
  }
  return Object.assign({}, miniAppObj);
};

const getSortedMiniAppsScreens = (
  appMeta,
  userUID,
  activeCustomRoleInfo,
  isDesc = false,
  withoutHiddenScreensAndGroups = false,
  // ^ this parameter now also takes care fror hidden groups(and children screens)
  withoutKanbanScreens = false,
) => {
  try {
    const screens = Object.assign({}, appMeta?.screens);
    const groups = Object.assign({}, appMeta?.groups);
    const isCustomUser =
      appMeta?.sharedWith?.[userUID]?.permission ===
      MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.CUSTOM_ROLE;
    const screenConfiguration = Object.assign(
      {},
      isCustomUser ? activeCustomRoleInfo?.screenConfiguration : null,
    );
    const sortedScreensList = new LioArray();
    forOwn(screens, (screenData, screenId) => {
      if (isCustomUser) {
        if (!(screenId in screenConfiguration)) {
          //screen not shared with this user
          return null;
        }
      }
      if (withoutKanbanScreens && screenData?.kanbanMeta) {
        // screens which are created for kanban view(s)
        // should be removed
        return null;
      }
      if (withoutHiddenScreensAndGroups && screenData.isHiddenForMe) {
        //remove hidden screens (for bottom bar only)
        return null;
      }
      if (withoutHiddenScreensAndGroups && !isNil(screenData?.groupId)) {
        //handles cases when parent group of this screen is hidden
        const groupId = screenData?.groupId;
        const isGroupHidden = Boolean(
          Object.values(groups)?.find((item) => item?.groupId === groupId)
            ?.isHiddenForMe,
        );
        if (isGroupHidden) return null;
      }
      sortedScreensList.binaryInsert(
        Object.assign({}, screenData, {screenId}),
        ['sortingIndex'],
        'duplicate',
        isDesc,
      );
    });
    return sortedScreensList;
  } catch (error) {
    captureError(error);
  }
  return [];
};

const getSortedMiniAppsScreensWithGroups = (
  appMeta = {},
  userUID = '',
  activeCustomRoleInfo = '',
  isDesc = false,
  withoutHiddenScreensAndGroups = false,
  withoutKanbanScreens = false,
) => {
  const isCustomUser =
    appMeta?.sharedWith?.[userUID]?.permission ===
    MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.CUSTOM_ROLE;
  const screenConfiguration = Object.assign(
    {},
    isCustomUser ? activeCustomRoleInfo?.screenConfiguration : null,
  );
  const {groups, screens} = Object.assign({}, appMeta);
  const currGroups = Array.isArray(groups) ? groups : [];
  const sortedGroups = new LioArray();

  currGroups?.forEach((groupData) => {
    const isScreen = has(groupData, 'screenId');
    const id = isScreen ? groupData.screenId : groupData.groupId;
    const {groupId} = groupData;

    if (isScreen) {
      // todo: handle for hiding kaban screens
      if (withoutKanbanScreens && screens?.[id]?.kanbanMeta) {
        // remove screens created for kanban view:
        return null;
      }
      if (isCustomUser && !(id in screenConfiguration)) {
        return null;
      }
      if (withoutHiddenScreensAndGroups && screens?.[id]?.isHiddenForMe) {
        return null;
      }

      sortedGroups.binaryInsert(
        Object.assign(
          {},
          {screenId: id, sortingIndex: screens?.[id].sortingIndex},
        ),
        ['sortingIndex'],
        'duplicate',
        isDesc,
      );
    } else if (groupId && has(groupData, 'screenIds')) {
      const updatedScreenIds = [...groupData.screenIds] ?? [];

      if (withoutHiddenScreensAndGroups && groupData.isHiddenForMe) {
        return null;
      }
      groupData?.screenIds?.forEach((screenId) => {
        if (isCustomUser && !(screenId in screenConfiguration)) {
          const index = updatedScreenIds?.indexOf(screenId);
          if (index !== -1) {
            updatedScreenIds?.splice(index, 1);
          }
        }
        if (
          withoutHiddenScreensAndGroups &&
          screens?.[screenId]?.isHiddenForMe
        ) {
          const index = updatedScreenIds?.indexOf(screenId);
          if (index !== -1) {
            updatedScreenIds?.splice(index, 1);
          }
        }
      });
      if (withoutHiddenScreensAndGroups && !updatedScreenIds?.length) {
        return null;
      }
      sortedGroups.binaryInsert(
        Object.assign({}, groupData, {
          screenIds: [...updatedScreenIds],
          groupId: id,
        }),
        ['sortingIndex'],
        'duplicate',
        isDesc,
      );
    }
  });
  return [...sortedGroups];
};

const getHiddenColIdsForScreenMiniApps = (screenId) => {
  const hiddenHeaderIds = [];
  try {
    const {miniApps, auth} = getReduxState();
    if (miniApps.activeAppId) {
      screenId = screenId ?? miniApps.activeScreenId;
      const appMeta = miniApps.miniApps[miniApps.activeAppId];
      const isCustomRole =
        appMeta?.sharedWith?.[auth.user.uid]?.permission ===
        MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.CUSTOM_ROLE;
      if (isCustomRole) {
        const columnRestrictions = Object.assign(
          {},
          miniApps.activeCustomRoleInfo?.screenConfiguration?.[screenId]
            ?.columnRestrictions,
        );
        Object.keys(columnRestrictions).forEach((key) => {
          if (columnRestrictions[key]?.isHidden) {
            hiddenHeaderIds.push(key);
          }
        });
      }
      if (Array.isArray(appMeta?.screens?.[screenId]?.hiddenColIds)) {
        hiddenHeaderIds.push(...appMeta.screens[screenId].hiddenColIds);
      }
    }
  } catch (error) {
    captureError(error);
  }
  return hiddenHeaderIds;
};

const getMappedVal = (
  rowObj,
  colId,
  documentData,
  userCountry,
  userPref,
  isOrganisationMode,
  organisationUserInfo,
) => {
  try {
    if (Array.isArray(colId)) {
      //CATALOG lAYOUT
      const {headerDataAsObj} = documentData;
      const catalogCellsData = (colId ?? [])
        .filter((id) => headerDataAsObj?.[id])
        .map((id) => {
          const headerObj = documentData.headerDataAsObj?.[id];
          const originalFieldType = getColumnFieldType(headerObj);
          let forceSkipFormulaFormatting = false;

          if (originalFieldType === FIELD_TYPE_ID.IMAGE) {
            return rowObj[id]?.val?.[0]?.uri || null;
          }
          // if (isBackgroundField(headerObj)) {
          //   return '-';
          // }

          if (ENV && originalFieldType === FIELD_TYPE_ID.SELECT_POP_UP) {
            return rowObj[id]?.val?.length ? rowObj[id] : '';
          }

          if (originalFieldType === FIELD_TYPE_ID.FORMULA) {
            const isOverwritePropEnabled =
              headerObj?.columnProperties?.[
                COLUMN_PROPERTY_KEYS.OVERWRITE_VALUE
              ] && !ColumnUtility.isDateOrTimeFormula(headerObj);

            forceSkipFormulaFormatting =
              isOverwritePropEnabled && rowObj?.[id]?.isOverridden;
          }

          const cellData = convertCellDataToText(
            rowObj[id],
            headerObj,
            userCountry,
            userPref,
            documentData.fileObj,
            rowObj,
            isOrganisationMode,
            organisationUserInfo,
            false,
            false,
            {
              forceSkipFormulaFormatting,
            },
          );
          return cellData.length ? cellData : '-';
        });

      return isEmpty(catalogCellsData) ? '' : catalogCellsData;
    } else {
      const headerObj = documentData.headerDataAsObj?.[colId];
      const isNotAllowed =
        isEmpty(headerObj) ||
        !MINI_APPS.ALLOWED_FIELD_TYPES.includes(headerObj.fieldType);
      // ||
      // isBackgroundField(headerObj);

      const originalFieldType = getColumnFieldType(headerObj);

      if (ENV && originalFieldType === FIELD_TYPE_ID.SELECT_POP_UP) {
        return rowObj[colId]?.val?.length ? rowObj[colId] : '';
      }

      const cellData = isNotAllowed
        ? ''
        : getColumnFieldType(headerObj) === FIELD_TYPE_ID.IMAGE
        ? checkCellContainsImage(rowObj[colId]?.val)
          ? rowObj[colId].val[0]?.uri
          : ''
        : convertCellDataToText(
            rowObj[colId],
            headerObj,
            userCountry,
            userPref,
            documentData.fileObj,
            rowObj,
            isOrganisationMode,
            organisationUserInfo,
          );
      return `${cellData?.length ? cellData : ''}`;
    }
  } catch {
    return '';
  }
};

const checkIfValidMapping = (currentFieldType, mappingKey) => {
  const specialMappingKeys = Object.keys(MINI_APPS.SPECIAL_FIELD_MAPPING);
  const specialFieldTypes = Object.values(MINI_APPS.SPECIAL_FIELD_MAPPING);

  // If no fieldType exists corresponding to the mappingKey -> valid
  if (!currentFieldType) return true;

  // If fieldType is not allowed -> invalid
  if (!MINI_APPS.ALLOWED_FIELD_TYPES.includes(currentFieldType)) {
    return false;
  }

  // If mappingKey is special and fieldType is not special -> invalid
  if (
    !specialMappingKeys.includes(mappingKey) &&
    specialFieldTypes.includes(currentFieldType)
  ) {
    return false;
  }

  // If mappingKey is not special and fieldType is special -> invalid
  if (
    specialMappingKeys.includes(mappingKey) &&
    !specialFieldTypes.includes(currentFieldType)
  ) {
    return false;
  }

  return true;
};

const getLayoutMappedValues = (
  screenMapping,
  screenMappedValuesConfig,
  rowObj,
  documentData,
  userCountry,
  userPref,
  isLoading,
  isOrganisationMode,
  organisationUserInfo,
) => {
  try {
    const mappedValues = {};
    const mappingConfig = {};
    if (!isLoading) {
      forOwn(screenMapping, (colId, mappingKey) => {
        let val = getMappedVal(
          rowObj,
          colId,
          documentData,
          userCountry,
          userPref,
          isOrganisationMode,
          organisationUserInfo,
        );

        const actualKey = MINI_APPS.MINI_APPS_FIELDS_INVERSE[mappingKey];

        // const headerDataAsObj = getHeaderDataAsObjWithoutBackgroundFields(
        //   documentData.headerData,
        // );
        const headerDataAsObj = documentData.headerDataAsObj;
        const columnObj = headerDataAsObj?.[colId];
        const originalFieldType = getColumnFieldType(columnObj);
        const currentColumnFieldType = columnObj?.fieldType;
        // checkIfValidMapping checks if the current column is mapped only to its allowed field types, if not then '-' is to be mapped.
        if (!checkIfValidMapping(originalFieldType, mappingKey)) {
          val = '-';
        }

        const isValid =
          mappingKey === MINI_APPS.MINI_APPS_FIELDS.rowStatus ||
          (mappingKey !== MINI_APPS.MINI_APPS_FIELDS.catalog
            ? originalFieldType === FIELD_TYPE_ID.SELECT_POP_UP
            : false)
            ? !isEmpty(val)
            : val?.length;

        mappedValues[actualKey] = isValid
          ? val
          : MINI_APPS.DEFAULT_EMPTY_CELL_VALUE;

        const configObj = Object.assign(
          {},
          screenMappedValuesConfig?.[mappingKey],
        );

        //FOR CATALOG LAYOUT - LOOP REQUIRED SINCE ARRAY
        if (mappingKey === MINI_APPS.MINI_APPS_FIELDS.catalog) {
          const colIds = Array.isArray(colId) ? colId : [];
          const config = [];
          colIds.forEach((id) => {
            const catalogConfig = {};
            //Returned null on empty case
            if (!configObj[id] || !headerDataAsObj?.[id]) {
              return null;
            }

            const label =
              headerDataAsObj?.[id] &&
              configObj[id]?.labelOption ===
                MINI_APPS.MAPPING_CONFIG.LABEL_VALUE_OPTION.CUSTOM_VALUE
                ? configObj[id]?.labelText
                : configObj[id]?.labelOption ===
                  MINI_APPS.MAPPING_CONFIG.LABEL_VALUE_OPTION.COLUMN_NAME
                ? getLanguageText(
                    headerDataAsObj?.[id]?.val,
                    headerDataAsObj?.[id],
                    userPref,
                  )
                : null;

            const idOriginalFieldType = getColumnFieldType(
              headerDataAsObj?.[id],
            );
            const textColor = configObj[id]?.textColor;
            if (textColor) {
              catalogConfig['textColor'] = textColor;
            }
            if (label) {
              catalogConfig['label'] = label;
            }

            if (idOriginalFieldType === FIELD_TYPE_ID.SELECT_POP_UP) {
              catalogConfig['fieldType'] = idOriginalFieldType;
              catalogConfig['valStyleObj'] = rowObj[id]?.valStyleObj ?? {};
            }

            config.push(catalogConfig);
          });

          Object.assign(mappingConfig, {
            [actualKey]: config,
          });
        } else {
          const label =
            headerDataAsObj?.[colId] &&
            configObj.labelOption ===
              MINI_APPS.MAPPING_CONFIG.LABEL_VALUE_OPTION.CUSTOM_VALUE
              ? configObj.labelText
              : configObj.labelOption ===
                MINI_APPS.MAPPING_CONFIG.LABEL_VALUE_OPTION.COLUMN_NAME
              ? getLanguageText(
                  headerDataAsObj?.[colId]?.val,
                  headerDataAsObj?.[colId],
                  userPref,
                )
              : null;
          const extraConfig = {};

          if (originalFieldType === FIELD_TYPE_ID.SELECT_POP_UP) {
            extraConfig['fieldType'] = originalFieldType;
            extraConfig['valStyleObj'] = rowObj[colId]?.valStyleObj ?? {};
          }

          Object.assign(mappingConfig, {
            [actualKey]: Object.assign(
              extraConfig,
              {textColor: configObj.textColor},
              {label},
            ),
          });
        }
      });
    }
    return [mappedValues, mappingConfig];
  } catch (e) {
    captureError(e);
  }
  return [{}, {}];
};

const fetchMiniAppsCategory = async (isBusinessCategory = true) => {
  // let appVersion;
  // const {getDeviceVersion} = require('../../imports');
  // appVersion = getDeviceVersion();

  try {
    const categorySnap = await firestore()
      .collection('miniAppsCategory')
      .where('display', '==', true)
      .where('type', '==', isBusinessCategory ? 'BUSINESS' : 'USECASE')
      // .where('minVersion', '<=', appVersion)
      // .orderBy('minVersion', 'asc')
      // .orderBy('priority', 'asc')
      .get();

    return categorySnap.docs;
  } catch (error) {
    captureError(error);
  }
};

const makeMiniAppsCategoryObjFromSnap = (snap, lang) => {
  const snapData = snap.data();
  return {
    name: snapData.name[lang],
    engName: snapData.name.EN,
    id: snap.id,
    icon: snapData.icon.image,
    nameObj: snapData.name,
  };
};

const getDashboardColumnList = (headerData) => {
  if (!headerData) {
    return [];
  }
  return (
    headerData.filter(
      (data) =>
        !TOTAL_NOT_ALLOWED.includes(data.fieldType) &&
        !data.hasPrevRowRef &&
        (data.fieldType === FIELD_TYPE_ID.TABLE
          ? !TOTAL_NOT_ALLOWED.includes(data.subType)
          : true),
    ) || []
  ); //columns on which dashboard is allowed
};

const getSplitbyDashboardColumnList = (headerData, dashboardConfig) => {
  if (!headerData) {
    return [];
  }
  const splitBySelectedColumnArr = [
    (dashboardConfig?.selectedSplitBy &&
      dashboardConfig?.selectedSplitBy?.id) ||
      '',
    (dashboardConfig?.selectedSplitBy2 &&
      dashboardConfig?.selectedSplitBy2?.id) ||
      '',
  ];
  return (
    headerData.filter(
      (col) =>
        (SPLIT_BY_ALLOWED_FIELDS.includes(col.fieldType) ||
          (col.fieldType === FIELD_TYPE_ID.TABLE
            ? SPLIT_BY_ALLOWED_FIELDS.includes(col.subType)
            : false)) &&
        !col.hasPrevRowRef &&
        !splitBySelectedColumnArr.includes(col?.id),
    ) || []
  );
};
const getOptionFilterColList = (headerData) => {
  if (!headerData) {
    return [];
  }
  return (
    headerData.filter(
      (col) =>
        (OPTION_COLUMN_FILTER.includes(col.fieldType) ||
          (col.fieldType === FIELD_TYPE_ID.TABLE
            ? OPTION_COLUMN_FILTER.includes(col.subType)
            : false)) &&
        !col.hasPrevRowRef,
    ) || []
  );
};

const getCustomViewLayoutFilterArray = (
  appData,
  screenId,
  screenLocalConfig,
  documentData,
) => {
  const filterArr = [];
  try {
    const {
      SCREEN_VIEW_LAYOUTS: {LAYOUT_TYPES, LAYOUT_PROPERTIES},
    } = MINI_APPS;
    const screeenViewLayout = appData?.screens?.[screenId]?.view;
    const isFullCalendarView =
      appData?.screens?.[screenId]?.customLayoutType ===
        LAYOUT_TYPES.CALENDAR &&
      !isEmpty(screeenViewLayout?.fullScreenCalendarMapping);

    if (
      appData?.screens?.[screenId]?.customLayoutType === LAYOUT_TYPES.CALENDAR
    ) {
      const startColId =
        screeenViewLayout.config?.[
          LAYOUT_PROPERTIES.CALENDAR.CONFIG_FIELDS.START_DATE
        ];
      const endColId =
        screeenViewLayout.config?.[
          LAYOUT_PROPERTIES.CALENDAR.CONFIG_FIELDS.END_DATE
        ];
      const getColumnType = (colId) =>
        colId
          ? getColumnFieldType(screeenViewLayout.colInfo?.[colId] ?? {}) ??
            getColumnFieldType(documentData.headerDataAsObj?.[colId] ?? {}) ??
            FIELD_TYPE_ID.DATE
          : null;
      const startFieldType = getColumnType(startColId);
      const endFieldType = getColumnType(endColId);

      const screenSelectedDate = (isStart, fieldType) =>
        isFullCalendarView
          ? isStart
            ? `${moment(screenLocalConfig?.[screenId]?.calendarSelectedDate)
                .startOf('month')
                .subtract(7, 'day')
                .format('DD/MM/YYYY')}${
                fieldType === FIELD_TYPE_ID.DATE_TIME
                  ? isStart
                    ? ' 12:00 AM'
                    : ' 11:59 PM'
                  : ''
              }`
            : `${moment(screenLocalConfig?.[screenId]?.calendarSelectedDate)
                .endOf('month')
                .add(7, 'day')
                .format('DD/MM/YYYY')}${
                fieldType === FIELD_TYPE_ID.DATE_TIME
                  ? isStart
                    ? ' 12:00 AM'
                    : ' 11:59 PM'
                  : ''
              }`
          : `${moment(
              screenLocalConfig?.[screenId]?.calendarSelectedDate,
            ).format('DD/MM/YYYY')}${
              fieldType === FIELD_TYPE_ID.DATE_TIME
                ? isStart
                  ? ' 12:00 AM'
                  : ' 11:59 PM'
                : ''
            }`;
      switch (screeenViewLayout.subType) {
        case LAYOUT_PROPERTIES.CALENDAR.SUB_TYPES.SPECIFIC_DATE: {
          filterArr.push({
            colId: startColId,
            selectedOptions: [
              screenSelectedDate(true, startFieldType, isFullCalendarView),
              screenSelectedDate(false, startFieldType, isFullCalendarView),
            ],
            fieldType: startFieldType,
          });
          break;
        }
        case LAYOUT_PROPERTIES.CALENDAR.SUB_TYPES.DATE_RANGE: {
          filterArr.push({
            colId: startColId,
            selectedOptions: [
              false,
              screenSelectedDate(false, startFieldType, isFullCalendarView),
            ],
            fieldType: startFieldType,
          });
          filterArr.push({
            colId: endColId,
            selectedOptions: [
              screenSelectedDate(true, endFieldType, isFullCalendarView),
              false,
            ],
            fieldType: endFieldType,
          });
          break;
        }
      }
    }
  } catch (err) {
    captureError(err);
  }
  return filterArr;
};

const isCustomViewLayoutFilteredScreen = (appData, screenId) => {
  try {
    const screeenViewLayout = appData?.screens?.[screenId]?.view;
    if (
      appData?.screens?.[screenId]?.customLayoutType ===
      MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.CALENDAR
    ) {
      return !isEmpty(screeenViewLayout.config);
    }
  } catch (err) {
    captureError(err);
  }
  return false;
};

const isCustomRoleFilteredScreen = (
  appData,
  userUID,
  screenId,
  activeCustomRoleInfo,
) => {
  try {
    const isCustomUser =
      appData?.sharedWith?.[userUID]?.permission ===
      MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.CUSTOM_ROLE;
    const viewAccess =
      activeCustomRoleInfo?.screenConfiguration?.[screenId]?.viewAccess;
    const isCustomView =
      isCustomUser &&
      [
        MINI_APPS.CUSTOM_VIEW_ACCESS.DATA_ADDED_BY_USER,
        MINI_APPS.CUSTOM_VIEW_ACCESS.DATA_ASSIGNED_TO_USER,
      ].includes(viewAccess);
    return [isCustomView, isCustomView ? viewAccess : null];
  } catch (err) {
    captureError(err);
  }
  return [false, null];
};

const checkIfFilteredDataScreen = (
  appData,
  userUID,
  screenId,
  activeCustomRoleInfo,
) => {
  try {
    const screenType = appData?.screens?.[screenId]?.type;
    switch (screenType) {
      case MINI_APPS.SCREEN_TYPE.DASHBOARD: {
        return false;
      }
      case MINI_APPS.SCREEN_TYPE.FILTERED_DATA: {
        return true;
      }
      case MINI_APPS.SCREEN_TYPE.DOCUMENT_DATA: {
        return (
          isCustomViewLayoutFilteredScreen(appData, screenId) ||
          isCustomRoleFilteredScreen(
            appData,
            userUID,
            screenId,
            activeCustomRoleInfo,
          )[0]
        );
      }
    }
  } catch (err) {
    captureError(err);
  }
  return false;
};

const getPreviewRowDataString = ({
  docId,
  rowData,
  miniAppsDocsData,
  userCountry,
  userPref,
}) => {
  let previewRowData = '';
  try {
    const documentData = miniAppsDocsData[docId] ?? {};
    const headerData = documentData.headerData ?? [];
    headerData.some((headerObj, index) => {
      const colId = headerObj.id;
      if (rowData[colId]) {
        const cellVal = convertCellDataToText(
          rowData[colId],
          headerObj,
          userCountry,
          userPref,
          documentData.fileObj,
          rowData,
          true,
          {},
          false,
          true,
        );
        if (cellVal?.length) {
          previewRowData += `${previewRowData.length ? ', ' : ''}${cellVal}`;
        }
      }
      if (previewRowData.length > 50) {
        if (index !== headerData.length - 1) {
          previewRowData += '...';
        }
        return true;
      }
      return false;
    });
  } catch {}
  return previewRowData;
};

const checkIfMiniAppDataLoading = (
  documentData,
  activeScreenId,
  isFiltered,
  screenSearchFilterState,
) =>
  Boolean(
    screenSearchFilterState?.isLoading ||
      (isFiltered
        ? documentData?.filterData?.[activeScreenId]?.isFilteredDataLoading
        : documentData?.isLoading),
  );

const emptyObjRef = {};

const initialEmptyData = Array.from({length: 7}, () => emptyObjRef);

const mapMiniAppStates = (
  state,
  showEmptyData,
  filterViewOnlyCols = false,
  screenIdForData = null,
  appIdForData = null,
  columnBasedOptions,
  showScreenHiddenColumns = false,
  {shouldHideSections = false} = {shouldHideSections: false},
) => {
  const {
    docsData,
    activeScreenId: screenId,
    activeAppId: appId,
    miniApps,
    searchFilterData,
    activeCustomRoleInfo,
  } = state.miniApps;
  const activeAppId = typeof appIdForData === 'string' ? appIdForData : appId;
  const activeAppMeta = miniApps[activeAppId] ?? emptyObjRef;
  const uid = state.auth.user?.uid;

  const activeScreenId =
    typeof screenIdForData === 'string' ? screenIdForData : screenId;

  const activeScreenData =
    activeAppMeta?.screens?.[activeScreenId] ?? emptyObjRef;
  const {showOnlyColumnIds, hideOnlyColumnIds, skipColPermissions} =
    Object.assign({}, columnBasedOptions);
  const isFilteredDataScreen = checkIfFilteredDataScreen(
    activeAppMeta,
    uid,
    activeScreenId,
    activeCustomRoleInfo,
  );
  const activeDocId = activeScreenData?.docs?.[0]?.docId;
  const activeDocOwnerUID = activeScreenData?.docs?.[0]?.docOwnerUID;
  const activeScreenHiddenColIds = showScreenHiddenColumns
    ? []
    : activeScreenData?.hiddenColIds ?? [];

  let documentData = docsData[activeDocId] ?? DEFAULT_DOC_DATA;

  const headerFilterConditions = [];
  if (Array.isArray(showOnlyColumnIds) && showOnlyColumnIds.length > 0) {
    headerFilterConditions.push((col) => showOnlyColumnIds.includes(col.id));
  }
  if (Array.isArray(hideOnlyColumnIds) && hideOnlyColumnIds.length > 0) {
    headerFilterConditions.push((col) => !hideOnlyColumnIds.includes(col.id));
  }
  const userPermission = activeAppMeta.sharedWith?.[uid]?.permission;
  if (
    skipColPermissions !== true &&
    activeAppMeta.sharedWith?.[uid]?.permission ===
      MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.CUSTOM_ROLE &&
    activeCustomRoleInfo?.screenConfiguration?.[activeScreenId]
      ?.columnRestrictions
  ) {
    const columnRestrictions =
      activeCustomRoleInfo?.screenConfiguration?.[activeScreenId]
        ?.columnRestrictions;
    if (!isEmpty(columnRestrictions)) {
      headerFilterConditions.push(
        (col) =>
          !columnRestrictions[col.id]?.isHidden &&
          (filterViewOnlyCols ? !columnRestrictions[col.id]?.isReadOnly : true),
      );
    }
  }

  if (activeScreenHiddenColIds?.length > 0) {
    headerFilterConditions.push(
      (col) => !activeScreenHiddenColIds.includes(col.id),
    );
  }

  if (headerFilterConditions.length > 0) {
    documentData = Object.assign({}, documentData, {
      headerData: (documentData?.headerData ?? []).filter((col) =>
        headerFilterConditions.every((condition) => condition(col)),
      ),
      sections: !shouldHideSections
        ? documentData?.sections ?? []
        : (documentData?.sections ?? [])
            .map((section) => {
              return {
                ...section,
                colIds: section.colIds.filter((colId) =>
                  headerFilterConditions.every((condition) =>
                    condition(documentData.headerDataAsObj?.[colId]),
                  ),
                ),
              };
            })
            .filter((section) => {
              return section.colIds.length > 0;
            }),
    });
  }

  let restrictedQuickFilterColumns = [];
  if (activeScreenData?.quickFilterColumns?.length > 0) {
    const quickFilterableHeaderData = documentData.headerData.filter(
      isQuickFilterableColumn,
    );
    restrictedQuickFilterColumns = (
      activeScreenData?.quickFilterColumns ?? []
    ).filter((quickFiltercolId) =>
      quickFilterableHeaderData.some(
        (colObj) => quickFiltercolId === colObj.id,
      ),
    );
  }

  const screenSearchFilterState =
    searchFilterData[activeScreenId] ?? emptyObjRef;
  const isSearchFilterActive = screenSearchFilterState.isActive;
  const isLoading = checkIfMiniAppDataLoading(
    documentData,
    activeScreenId,
    isFilteredDataScreen,
    screenSearchFilterState,
  );

  // TODO: REMOVE - {integrations: {INTERAKT : {FnQe65Kb4rA2ss3p8ptI : "RUNNING"}}}
  const interaktId = activeAppMeta.integrations?.['INTERAKT']
    ? Object.keys(activeAppMeta.integrations['INTERAKT'])[0]
    : null;

  return {
    isLoading,
    interaktId,
    listData:
      (isLoading
        ? showEmptyData
          ? initialEmptyData
          : []
        : screenSearchFilterState.isActive || screenSearchFilterState.isLoading
        ? screenSearchFilterState.tableData
        : isFilteredDataScreen
        ? documentData.filterData?.[activeScreenId]?.tableData
        : documentData.tableData) ?? [],
    activeScreenId,
    activeAppId,
    activeDocId,
    documentData,
    isFilteredDataScreen,
    activeScreenData,
    screenSearchFilterState,
    activeAppMeta,
    isSearchFilterActive,
    activeDocOwnerUID,
    restrictedQuickFilterColumns,
    userPermission,
    activeScreenHiddenColIds,
    activeCustomRoleInfo,
  };
};

const addColumnCF = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.ADD_COLUMN_CF, obj);

const deleteColumnCF = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.DELETE_COLUMN_CF, obj);

const moveColumnsCF = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.MOVE_COLUMNS_CF, obj);

const editColumnCF = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.EDIT_COLUMN_CF, obj);

const getUniqueColumnSetForDoc = (fileObj, headerDataAsObj) => {
  const uniqueKeySetColumns = new LioArray();
  try {
    forOwn(
      Object.assign({}, fileObj?.uniqueKeySet),
      ({columnIds: colIds, index}, id) => {
        let isInvalid = false;
        const name = colIds
          .map((colId) => {
            if (headerDataAsObj[colId]) {
              return headerDataAsObj[colId].val ?? '';
            }
            isInvalid = true;
            return 'Deleted Column';
          })
          .join(', ');
        const dataObj = {colIds, name, index, id, isInvalid};
        uniqueKeySetColumns.binaryInsert(dataObj, ['index'], 'duplicate', true);
      },
    );
  } catch (err) {
    captureError(err);
  }
  return uniqueKeySetColumns;
};

const getHeaderDataForMiniAppRowEdit = (
  mappedHeader, //header data for doc of this edit row
  activeAppMeta, //meta data for the active app
  userUID, //active user id
  activeCustomRoleInfo, //custom role info for the active app
  activeScreenId, //screen id for the active screen of the app
  originalRowObj, //row obj for the row being edited (before edit)
  isNewRow = false, //is adding new row
  isDuplicatingRow = false, //is duplicating row
  columnBasedOptions,
  showViewAccesColumn = false,
) => {
  if (!Array.isArray(mappedHeader)) {
    return [[], false];
  }
  const colObjFilters = [];

  // Push 'summary' columns in headerData only if 'columnBasedOptions.showSummaryCols' is true
  colObjFilters.push((colObj) => {
    if (!isEmpty(colObj?.summaryInfo)) {
      if (columnBasedOptions?.showSummaryCols) {
        return true;
      }
      return false;
    }
    return true;
  });

  let isEditMissingValuesOnly = false;
  const isCustomRole =
    activeAppMeta?.sharedWith?.[userUID]?.permission ===
    MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.CUSTOM_ROLE;
  if (columnBasedOptions?.skipColPermissions !== true && isCustomRole) {
    const activeDocPermissionProps =
      activeCustomRoleInfo?.screenConfiguration?.[activeScreenId];
    const columnRestrictions = activeDocPermissionProps?.columnRestrictions;

    if (!isEmpty(columnRestrictions)) {
      colObjFilters.push((colObj) =>
        showViewAccesColumn
          ? !columnRestrictions[colObj?.id]?.isHidden
          : !columnRestrictions[colObj?.id]?.isReadOnly &&
            !columnRestrictions[colObj?.id]?.isHidden,
      );
    }
    isEditMissingValuesOnly =
      activeDocPermissionProps?.entryProperties ===
      MINI_APPS.CUSTOM_ENTRY_PROPERTY.CAN_EDIT_MISSING_VALUES;
    if (!isNewRow && !isDuplicatingRow) {
      if (isEditMissingValuesOnly) {
        colObjFilters.push(
          (colObj) =>
            !checkIfValueExistOnCell(
              originalRowObj?.[colObj?.id],
              colObj,
              originalRowObj,
            ),
        );
      }
    }
  }
  return [
    mappedHeader.filter((colObj) =>
      colObjFilters.every((filterFunction) => filterFunction?.(colObj)),
    ),
    isEditMissingValuesOnly,
  ];
};

const emptyArrRef = [];

// TODO : Handle this based on doc id instead of all the isNewRow, isDuplicate, isListEntry, isListEditEntry
/**
 *
 * @param {{
 *  listColumns: object;
 *  miniApps: object;
 *  auth: object;
 * }} state
 * @param {{
 *   screenIdToUse: string;
 *   showOnlyColumnIds: string[];
 *   hideOnlyColumnIds: string[];
 *   skipColPermissions: boolean;
 *   headerData: object[];
 *   isNewRow: boolean;
 *   isDuplicate: boolean;
 * }} param1
 * @param param1.screenIdToUse - Mandatory
 * // isListEntry: boolean;
 * // isListEditEntry: boolean;
 * // parentFileHeaderData: object[];
 * @param {object} rowObj
 * @returns
 */
const getHeaderDataForMiniAppRowEditUsingRedux = (
  state,
  {
    screenIdToUse,
    showOnlyColumnIds,
    hideOnlyColumnIds,
    skipColPermissions,
    isNewRow,
    isDuplicate,
    showSummaryCols,
  },
  rowObj,
) => {
  const {activeAppId, miniApps, activeCustomRoleInfo} = state.miniApps;
  const {user} = state.auth;

  const columnBasedOptions = {
    showOnlyColumnIds,
    hideOnlyColumnIds,
    skipColPermissions,
    showSummaryCols,
  };
  const {documentData: activeScreenDocumentData, activeScreenId} =
    mapMiniAppStates(
      state,
      false,
      false,
      screenIdToUse,
      null,
      columnBasedOptions,
    );

  const activeAppMeta = miniApps[activeAppId];

  const mappedHeader = activeScreenDocumentData.headerData ?? emptyArrRef;

  return getHeaderDataForMiniAppRowEdit(
    mappedHeader,
    activeAppMeta,
    user?.uid,
    activeCustomRoleInfo,
    activeScreenId,
    rowObj,
    isNewRow,
    isDuplicate,
    columnBasedOptions,
  );
};

const getHeaderDataForMiniAppRowDetails = (
  mappedHeader, //header data for doc of this row details
  activeAppMeta, //meta data for the active app
  userUID, //active user id
  activeCustomRoleInfo, //custom role info for the active app
  screenId, //screen id for the active screen of the app
) => {
  let data = mappedHeader;
  const isCustomRole =
    activeAppMeta?.sharedWith?.[userUID]?.permission ===
    MINI_APPS.MINI_APPS_SHARE_PERMISSION_TYPE.CUSTOM_ROLE;
  if (isCustomRole) {
    const activeScreenPermissionProps =
      activeCustomRoleInfo?.screenConfiguration?.[screenId];
    const columnRestrictions = activeScreenPermissionProps?.columnRestrictions;
    if (!isEmpty(columnRestrictions)) {
      data = data.filter((colObj) => !columnRestrictions[colObj?.id]?.isHidden);
    }
  }
  return data;
};

const updateQuickFilterColumnsForScreenCloud = (obj) =>
  callCloudFunction(
    CLOUD_FUNCTION_PATHS.UPDATE_QUICK_FILTER_COLUMNS_FOR_SCREEN,
    obj,
  );

const deleteRowsDateRecurring = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.DELETE_ROWS_RECURRING, obj);

const addEditMultipleRows = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.ADD_EDIT_MULTIPLE_ROWS, obj);

const getHeaderDataForQuickFilterForScreen = (headerData, screenId) => {
  const headerDataWithRestrictions = [];
  try {
    const hiddenColIdsForScreen = getHiddenColIdsForScreenMiniApps(screenId);

    headerDataWithRestrictions.push(
      ...headerData
        .filter((colObj) => !hiddenColIdsForScreen.includes(colObj.id))
        .filter(isQuickFilterableColumn),
    );
  } catch (error) {
    captureError(error);
  }
  return headerDataWithRestrictions;
};

const mapColumnHeaderDataWithColumnId = (
  mappingObj = {},
  headerDataAsObj = {},
  layout = '',
) => {
  return Object.keys(Object.assign({}, mappingObj)).reduce((mappings, key) => {
    if (
      layout === MINI_APPS.LAYOUTS.CATALOG &&
      [MINI_APPS_FIELDS.catalog, MINI_APPS_FIELDS.catalogueVarient].includes(
        key,
      )
    ) {
      if (key === MINI_APPS_FIELDS.catalog) {
        const headerDataAsObject = headerDataAsObj;
        mappings[key] = mappingObj?.[key]?.map(
          (colId) => headerDataAsObject?.[colId],
        );
      } else {
        mappings[key] = mappingObj[key];
      }
    } else {
      const colId = mappingObj[key];
      mappings[key] = headerDataAsObj[colId];
    }

    return mappings;
  }, {});
};

const searchInReportMeta = (reportMeta, searchStr) => {
  try {
    const searchStrLower = searchStr.toLowerCase();
    return ['addedBy.name', 'title'].some((property) =>
      (get(reportMeta, property) ?? '').toLowerCase().includes(searchStrLower),
    );
  } catch (err) {
    captureInfo({reportMeta, searchStr});
    captureError(err);
    return false;
  }
};

const generatePDF = async (obj) => {
  try {
    // const pdfConfig = await getPdfConfigFireStore(obj?.pdfConfigId);
    return callCloudFunction(
      CLOUD_FUNCTION_PATHS.GENERATE_PDF,
      Object.assign({}, obj),
      // Object.assign({}, obj, {pdfConfig: pdfConfig.pdfConfig}),
    );
  } catch (err) {
    captureError(err);
  }
};

const getSortedSectionedHeaderDataAsArray = (
  sections = {},
  headerDataAsObj,
  headerData,
) => {
  if (isNil(sections) || isEmpty(sections)) {
    // when there are no sections present
    return [];
  }
  let sortedSections = new LioArray();
  let colIdsAlreadyInSections = [];
  forOwn(sections, (sectionItem) => {
    const {sectionId, colIds} = sectionItem;
    if (!isNil(sectionId)) {
      if (!isNil(colIds)) {
        colIdsAlreadyInSections = [...colIdsAlreadyInSections.concat(colIds)];
      }
    } else {
      colIdsAlreadyInSections.push(sectionItem.colId);
    }
  });

  // headerDataAsObjNotInSections stores colObj which are there in
  // headerDataAsObj but not in sections
  const headerDataAsObjNotInSections = omit(
    headerDataAsObj,
    colIdsAlreadyInSections,
  );

  // headerDataNotInSections stores colObj in sorted order
  // which are there in headerData but not in sections
  const headerDataNotInSections = [
    ...headerData.filter((colObj) => {
      return Object.prototype.hasOwnProperty.call(
        headerDataAsObjNotInSections,
        colObj.id,
      );
    }),
  ];

  forOwn(sections, (sectionItem) =>
    sortedSections.binaryInsert(sectionItem, ['sortingIndex'], 'duplicate'),
  );

  // stores sortingIndex of last element in sortedSections array
  const lastInsertedElementSortingIndex =
    sortedSections[sortedSections.length - 1].sortingIndex;

  sortedSections.forEach((sectionItem) => {
    if (!isNil(sectionItem.sectionId)) {
      // handles for sections
      // filters out such colIds from each section
      // which are not present in headerDatAsObj
      if (sectionItem?.colIds?.length > 0) {
        sectionItem.colIds = [
          ...sectionItem.colIds.filter((colId) => headerDataAsObj[colId]),
        ];
      }
    } else {
      return;
    }
  });

  const colIdsInOtherSections = [];
  headerDataNotInSections.forEach((colObj) =>
    colIdsInOtherSections.push(colObj.id),
  );

  colIdsInOtherSections.length > 0 &&
    sortedSections.binaryInsert(
      Object.assign({
        sectionId: OTHERS_SECTION_ID,
        sectionName: 'Others',
        colIds: [...colIdsInOtherSections],
        sortingIndex: lastInsertedElementSortingIndex + 1,
      }),
      ['sortingIndex'],
      'duplicate',
    );
  // removing sortingIndex key from all the sectionObjects
  sortedSections = sortedSections.map((sectionObj) => {
    const {sortingIndex, ...sectionObjWithoutSortingIndex} = sectionObj;
    return sectionObjWithoutSortingIndex;
  });

  return [...sortedSections];
};

const getSectionHeaderDataAsObject = (sectionDataAsArray) => {
  let sectionHeaderDataAsObj = {};
  sectionDataAsArray.forEach((sectionItem, index) => {
    const {sectionId} = sectionItem;
    if (sectionId !== OTHERS_SECTION_ID) {
      const sectionItemWithSortingIndex = Object.assign({}, sectionItem, {
        sortingIndex: index,
      });
      sectionHeaderDataAsObj = Object.assign({}, sectionHeaderDataAsObj, {
        [sectionId]: sectionItemWithSortingIndex,
      });
    } else {
      return;
    }
  });
  return sectionHeaderDataAsObj;
};

const onDateChangeInCalendarView = (
  {calendarSelectedDate, selectedDate},
  {dispatch, onCompletion},
) => {
  const areDatesEqual = (date1, date2) =>
    date1?.toISOString?.() === date2?.toISOString?.();
  const state = getReduxState();
  const screenSearchFilterState =
    state.miniApps.searchFilterData[state.miniApps.activeScreenId] ?? {};
  const {activeScreenId, documentData} = mapMiniAppStates(state);
  if (!areDatesEqual(selectedDate, calendarSelectedDate)) {
    dispatch(updateScreenLocalConfig(activeScreenId, {calendarSelectedDate}));
    if (screenSearchFilterState?.isActive) {
      const isFilter = !isEmpty(screenSearchFilterState.filterOptions);
      let filterArr, searchedText;
      if (isFilter) {
        filterArr = getFiltersArrFromSelectedOptionsObj(
          screenSearchFilterState.filterOptions,
          documentData.headerData,
          {},
          true,
        )?.filterArr;
      } else if (screenSearchFilterState.searchedText?.length) {
        searchedText = screenSearchFilterState.searchedText;
      }
      if (filterArr || searchedText) {
        dispatch(
          startSearchFilterOnMiniAppScreen(
            {filterArr, searchedText},
            !isFilter,
            activeScreenId,
          ),
        );
      }
    }
    dispatch(getMiniAppPaginatedDocData(null, {isCalendarView: true}));
    onCompletion?.();
  }
};

const getOrderedHeaderDataBasedOnSections = (
  sortedSectionData,
  headerDataAsObj,
  headerData,
  updatedHeaderData,
) => {
  if (sortedSectionData.length === 0) {
    return updatedHeaderData;
  }
  const sortedHeaderData = [];
  sortedSectionData.forEach((sectionItem) => {
    const {colIds} = sectionItem;
    if (!isNil(colIds)) {
      colIds.forEach((columnId) =>
        sortedHeaderData.push(headerDataAsObj[columnId]),
      );
    }
  });
  return [...sortedHeaderData];
};
/**
 *
 * @type {() => DEFAULT_DOC_DATA}
 */
const getDocDataFromMiniAppState = (docId, state) => {
  return state.miniApps.docsData[docId] ?? DEFAULT_DOC_DATA;
};

const generateGmailAuthURL = async (obj) =>
  callCloudFunction(
    CLOUD_FUNCTION_PATHS.GENERATE_GMAIL_AUTH_URL,
    Object.assign({}, obj),
  );

const deleteIntegrationForMiniApp = async (obj) =>
  callCloudFunction(
    CLOUD_FUNCTION_PATHS.DELETE_INTEGRATION,
    Object.assign({}, obj),
  );

const getIntegrationData = async (integrationId) => {
  return firestore()
    .collection('miniAppIntegrations')
    .doc(integrationId)
    .then((data) => data.data());
};

const setAutomationIdInIntegrationData = async (
  integrationId,
  automationId,
  docId,
) => {
  return firestore()
    .collection('miniAppIntegrations')
    .doc(integrationId)
    .update({
      [`data.docs.${docId}.${automationId}`]: true,
    });
};

const deleteAutomationIdFromIntegrationData = async (
  integrationId,
  automationId,
  docId,
) => {
  return firestore()
    .collection('miniAppIntegrations')
    .doc(integrationId)
    .update({
      [`data.docs.${docId}.${automationId}`]:
        firestore(true).FieldValue.delete(),
    });
};

const getMiniAppEmailsObjects = async (appData, emailDataList) => {
  const {miniAppIntegrations} = appData || {};
  const platform = MINI_APP_INTEGRATION_PLATFORMS.GOOGLE_EMAIL_CONNECT;

  const googleEmailConnect = miniAppIntegrations?.[platform] || {};
  const googleEmailConnectIDs = Object.keys(googleEmailConnect).filter(
    (id) => googleEmailConnect[id] === 'RUNNING',
  );

  if (!isEmpty(googleEmailConnect)) {
    const addedIds = emailDataList.map((item) => item.id);
    const addedIdMap = emailDataList.reduce((acc, item) => {
      acc[item.id] = item;
      return acc;
    }, {});

    // Early return if all the emails are already added
    if (googleEmailConnectIDs.every((id) => addedIds.includes(id))) {
      return googleEmailConnectIDs.map((id) => ({
        ...addedIdMap[id],
      }));
    }

    const integrationPromises = googleEmailConnectIDs.map((id) => {
      const ref = firestore().collection('miniAppIntegrations').doc(id);
      return ref.get().then((snap) => (snap.exists ? snap.data() : {}));
    });

    return Promise.all(integrationPromises).then((results) => {
      const emails = [];

      results.forEach((result, index) => {
        const noOfActions = Object.keys(result?.data?.docs || {}).reduce(
          (acc, curr) => {
            acc += Object.keys(result?.data?.docs?.[curr] || {}).length;
            return acc;
          },
          0,
        );
        const hasError = !isEmpty(result?.data?.token_error);
        // const firestoreTimestampUTC = result?.data?.expiry_date;
        // const currentUTCDate = moment().utc();
        // const expiry_date = moment(firestoreTimestampUTC?.toDate()).utc();
        // const isExpired = currentUTCDate.isAfter(expiry_date);

        emails.push({
          email: result?.data?.email,
          name: result?.data?.given_name,
          type: 'GMAIL',
          id: googleEmailConnectIDs[index],
          noOfActions,
          connected: true,
          errored: hasError,
          data: result,
        });
      });

      return emails;
    });
  }

  return [];
};

// Fetch valid rows from rowIds array after applying filters/custom role restrictions
const fetchRowsFromElastic = async (
  rowIds = [],
  miniAppId = null,
  screenIdForApp = null,
  getHitSize = false,
) => {
  try {
    const {
      activeDocId: docId,
      activeScreenData: currScreenMeta,
      documentData,
      activeScreenId,
      activeAppMeta,
    } = mapMiniAppStates(
      getReduxState(),
      false,
      false,
      screenIdForApp,
      miniAppId,
    );
    const {
      miniApps: {activeCustomRoleInfo},
      auth,
    } = getReduxState();
    const screenId = activeScreenId;
    const filters =
      currScreenMeta.type === MINI_APPS.SCREEN_TYPE.FILTERED_DATA
        ? currScreenMeta.docs[0].filterOptions.slice()
        : [];

    const [isCustomViewAccess, viewAccess] = isCustomRoleFilteredScreen(
      activeAppMeta,
      auth.user.uid,
      screenId,
      activeCustomRoleInfo,
    ); //get view permissiong params for custom roles

    const dataObj = {
      isPaginated: false, // fetch all valid rows if full calender view is enabled
      textToSearch: '',
      docId,
      originalDocumentId: docId,
      customSorting: documentData.fileObj?.customSorting,
      filters,
      headerIdFieldMapping: getHeaderFieldMappingForElasticSearch(
        documentData.headerData,
      ),
      ...commonBodyParamsForElastic(auth),
      ...(isCustomViewAccess ? {viewAccess} : {}),
      rowIdsToSearchOn: rowIds,
      getAsFirestoreDoc: true,
      forceSkipSearchFilterCheck: true,
    };

    const data = await searchFilterOnMiniAppScreenCloudFunction(dataObj);
    if (!data || !data.success || isEmpty(data.response)) {
      handleCloudErrorMsgAndLogging(data, dataObj, auth.userPref);
    } else {
      if (getHitSize) {
        return data?.response?.hitsSize;
      }

      const {tableData, rowIdDataMap} = processRowsDataForMappedRowId(
        {docs: data.response.tableData ?? []},
        null,
        [],
        null,
        {},
        {},
        {
          useDocDataAsObject: true,
        },
      );
      return {success: true, tableData, rowIdDataMap};
    }
  } catch (error) {
    captureError(error);
  }
  return {success: false};
};

const getUpdatedHeaderDataAndHeaderDataAsObj = (
  originalHeaderData,
  originalHeaderDataAsObj,
  fieldOperationType,
  updatedColObj = {},
) => {
  if (fieldOperationType === FIELD_OPERATION_TYPE.ADD) {
    if (isNil(updatedColObj) || isNil(updatedColObj?.id)) {
      return;
    }
    return {
      headerData: [...originalHeaderData, updatedColObj],
      headerDataAsObj: Object.assign({}, originalHeaderDataAsObj, {
        [updatedColObj.id]: updatedColObj,
      }),
    };
  } else if (fieldOperationType === FIELD_OPERATION_TYPE.EDIT) {
    if (isNil(updatedColObj) || isNil(updatedColObj?.id)) {
      return;
    }
    const {id} = updatedColObj;
    return {
      headerData: originalHeaderData.map((colObj) =>
        colObj.id === id ? {...colObj, ...updatedColObj} : colObj,
      ),
      headerDataAsObj: Object.assign({}, originalHeaderDataAsObj, {
        [updatedColObj.id]: updatedColObj,
      }),
    };
  } else if (fieldOperationType === FIELD_OPERATION_TYPE.DELETE) {
    if (isNil(updatedColObj) || isNil(updatedColObj?.id)) {
      return;
    }
    const {id} = updatedColObj;
    return {
      headerData: originalHeaderData.filter((colObj) => colObj.id !== id),
      headerDataAsObj: omit(Object.assign({}, originalHeaderDataAsObj), [id]),
    };
  } else {
    return;
  }
};

// this function updates screenConfiguration object
// to include kanban screenIds as well in screenConfiguration
const getUpdatedScreenConfigurationForKanban = (
  screenConfiguartionObj,
  activeAppMeta,
) => {
  const {screens} = activeAppMeta;
  const isKanbanConfiguredOnScreen = (screenObj = {}) => {
    const {view} = screenObj;
    const {kanbanScreenIds, kanbanSelectBoxColId} =
      view?.[MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN] ?? {};
    return !isEmpty(kanbanScreenIds ?? []) && !isEmpty(kanbanSelectBoxColId);
  };
  const isValidKanbanFilteredScreen = (screenId = {}) => {
    const screenObj = screens?.[screenId] ?? {};
    const {kanbanMeta} = screenObj;
    if (isEmpty(kanbanMeta)) {
      return false;
    }
    const {parentScreenId} = kanbanMeta ?? {};
    const parentScreenObj = screens?.[parentScreenId];
    const parentScreenKanbanScreenIds =
      parentScreenObj?.view?.[MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN]
        ?.kanbanScreenIds ?? [];
    if (!parentScreenKanbanScreenIds?.includes(screenId)) {
      return false;
    }
    return true;
  };
  let updatedScreenConfiguartionObj = Object.assign({}, screenConfiguartionObj);

  // REMOVES ANY KANBAN FILTERED SCREENS
  // WHICH NO LONGER EXISTS
  Object.keys(screenConfiguartionObj).forEach((screenId) => {
    const screenObj = screens?.[screenId] ?? {};
    if (isEmpty(screenObj)) {
      // REMOVING BECAUSE SCREEN DOESN'T EXISTS
      // ANY MORE
      updatedScreenConfiguartionObj = omit(updatedScreenConfiguartionObj, [
        screenId,
      ]);
    }
    if (!isEmpty(screenObj?.kanbanMeta)) {
      //^ 1st check : is a kanban filtered screen
      if (!isValidKanbanFilteredScreen(screenId)) {
        updatedScreenConfiguartionObj = omit(updatedScreenConfiguartionObj, [
          screenId,
        ]);
      }
    }
  });

  // ADD FILTERED SCREENS PERMISSION TOO
  // SAME AS PARENT(KANBAN) SCREEN
  Object.keys(screenConfiguartionObj).forEach((screenId) => {
    if (isKanbanConfiguredOnScreen(screens?.[screenId])) {
      const {kanbanScreenIds} =
        screens?.[screenId]?.view?.[
          MINI_APPS.SCREEN_VIEW_LAYOUTS.LAYOUT_TYPES.KANBAN
        ] ?? {};
      if (Array.isArray(kanbanScreenIds) && kanbanScreenIds.length > 0) {
        kanbanScreenIds.forEach((kanbanScreenId) => {
          updatedScreenConfiguartionObj = Object.assign(
            {},
            updatedScreenConfiguartionObj,
            {[kanbanScreenId]: screenConfiguartionObj?.[screenId]},
          );
        });
      }
    }
  });
  return updatedScreenConfiguartionObj;
};

const getDataObjForaddEditScreen = (
  {
    screenName,
    documentId,
    iconName,
    layoutId,
    addItemText, //entry Options
    entryColIds, //entry Options
    entryHideColIds, //entry Options
    disableEdit = false, //entry Options
    disableEntry = false, //entry Options
    mapping,
    mappedValuesConfig,
    view, // config for screen viewtype : Object
    hiddenColIds, //columns to hide from this screen
    type,
    filterOptions, //Should be prechecked for validity
    isEdit = false,
    screenId = null, //required in case of isEdit = true
    isDelete = false, //only appId and screenId required in case of isDelete = true
    isModifyScreenViewLayout = false, //Pass to modify screen View type Layout
    alwaysFullScreen = null,
    customLayoutConfigs,
    customLayoutType,
    //  noOfCardsInARow = null, // only required for catalog cards
    kanbanMeta = null, // only used for screens created/modified/deleted for kanban view configuration
    kanbanScreenIds = null, // only used for kanban configuration
    doNotShowToast = false,
    doNotChangeScreen = false,
  },
  state,
) => {
  const {
    auth: {userPref, user},
    miniApps: {activeAppId, activeScreenId, miniApps, docsData},
    elasticDashboards: {activeDashboardId},
  } = state;
  type =
    type in MINI_APPS.SCREEN_TYPE ? type : MINI_APPS.SCREEN_TYPE.DOCUMENT_DATA;

  const isFilteredDataScreen = type === MINI_APPS.SCREEN_TYPE.FILTERED_DATA;

  const activeScrenMeta = miniApps[activeAppId]?.screens?.[screenId];
  const activeScreenDoc = activeScrenMeta?.docs?.[0];

  // const viewTypeOfScreen = activeScrenMeta?.view;
  const screenCustomLayoutConfigs = activeScrenMeta?.customLayoutConfigs;
  const screenCustomLayoutType = activeScrenMeta?.customLayoutType;
  const screenDisableEntry = activeScrenMeta?.disableEntry;
  const screenDisableEdit = activeScrenMeta?.disableEdit;
  const screenView = Object.assign({}, activeScrenMeta?.view, view);
  const modifyViewLayoutScreenObject = isModifyScreenViewLayout
    ? {
        screenName: activeScrenMeta?.screenName,
        documentId: activeScreenDoc?.docId,
        iconName: activeScrenMeta?.screenIcon,
        layoutId: activeScrenMeta?.layoutId,
        mapping: mapColumnHeaderDataWithColumnId(
          activeScreenDoc?.mapping,
          docsData[activeScreenDoc?.docId]?.headerDataAsObj,
          activeScrenMeta?.layoutId,
        ),
        ...(isEmpty(screenView) ? {} : {view: screenView}),
        customLayoutConfigs,
        customLayoutType: customLayoutType ?? screenCustomLayoutType,
        hiddenColIds: activeScrenMeta?.hiddenColIds,
        isEdit,
        screenId,
        type: activeScrenMeta?.type,
        filterOptions:
          activeScrenMeta?.type === MINI_APPS.SCREEN_TYPE.FILTERED_DATA
            ? mapFilterArrForStoringFirestore(activeScreenDoc?.filterOptions)
            : activeScreenDoc?.filterOptions,
        mappedValuesConfig: activeScreenDoc?.mappedValuesConfig,
        addItemText: activeScrenMeta?.addItemText,
        entryColIds: activeScrenMeta?.entryColIds,
        entryHideColIds: activeScrenMeta?.entryHideColIds,
        disableEdit: disableEdit || screenDisableEdit,
        disableEntry: disableEntry || screenDisableEntry,
      }
    : null;

  return Object.assign(
    {},
    {screenId, appId: activeAppId},
    {groupId: activeScrenMeta?.groupId},
    isDelete
      ? {isDelete}
      : type === MINI_APPS.SCREEN_TYPE.DASHBOARD
      ? {type, screenName, iconName, isEdit, screenId, alwaysFullScreen}
      : isModifyScreenViewLayout //Change view Layout type of Screen
      ? modifyViewLayoutScreenObject
      : {
          screenName,
          documentId,
          iconName,
          layoutId,
          mapping,
          ...(isEmpty(screenView) ? {} : {view: screenView}),
          ...(screenCustomLayoutConfigs
            ? {customLayoutConfigs: screenCustomLayoutConfigs}
            : {}),
          ...(screenCustomLayoutType
            ? {customLayoutType: screenCustomLayoutType}
            : {}),
          hiddenColIds,
          isEdit,
          screenId,
          type,
          filterOptions: isFilteredDataScreen
            ? mapFilterArrForStoringFirestore(filterOptions)
            : filterOptions,
          mappedValuesConfig,
          addItemText,
          entryColIds,
          entryHideColIds,
          disableEdit,
          disableEntry,
          ...(!isNil(kanbanMeta) ? {kanbanMeta} : {}),
        },
  );
};

const generateOtpCF = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.GENERATE_OTP_MINI_APPS, obj, {
    timeout: 300000,
  });

const verifyOtpCF = (obj) =>
  callCloudFunction(CLOUD_FUNCTION_PATHS.VERIFY_OTP_MINI_APPS, obj, {
    timeout: 300000,
  });

const getAllFilesContentAsZip = async (payload) => {
  try {
    return await callCloudFunction(
      CLOUD_FUNCTION_PATHS.GET_ALL_FILES_CONTENT_AND_ZIP,
      Object.assign({}, payload),
    );
  } catch (error) {
    handleCloudError(error);
  }
};

const getDataFromEndpoint = async (payload) => {
  try {
    return await callCloudFunction(
      CLOUD_FUNCTION_PATHS.FETCH_DATA_FROM_ENDPOINT,
      Object.assign({}, payload),
    );
  } catch (error) {
    handleCloudError(error);
  }
};

export {
  DEFAULT_DOC_DATA,
  FILTER_ONLY_DATA,
  DEFAULT_SEARCH_FILTER_DATA,
  makeMiniAppsCategoryObjFromSnap,
  fetchMiniAppsCategory,
  createEditMiniAppCloudFunction,
  getFormattedMiniAppObj,
  getHeaderForImportExcelFile,
  getCustomViewLayoutFilterArray,
  getSortedMiniAppsScreens,
  searchFilterOnMiniAppScreenCloudFunction,
  addEditScreenMiniAppCloudFunction,
  addEditMultipleScreenMiniAppCloudFunction,
  duplicateScreenMiniAppCloudFunction,
  getUniqueColumnSetForDoc,
  getLayoutMappedValues,
  manageScreenMiniAppsCloudFunction,
  addEditMiniAppEntry,
  addEditFileObjPropertiesCF,
  changeMiniAppProperty,
  createAppFromAppStoreCloudFunction,
  getDashboardColumnList,
  getSplitbyDashboardColumnList,
  createEditMiniAppCustomRolesCloud,
  shareChangePermissionMiniAppCloud,
  removeSharedUserOrLeaveAppCloud,
  checkIfFilteredDataScreen,
  isCustomViewLayoutFilteredScreen,
  isCustomRoleFilteredScreen,
  checkIfMiniAppDataLoading,
  mapMiniAppStates,
  getOptionFilterColList,
  deleteRowCF,
  getHeaderDataForMiniAppRowEdit,
  deleteSampleDataFromMiniApps,
  addEditActionButtonsCloudFunction,
  getHeaderDataForMiniAppRowDetails,
  getPreviewRowDataString,
  getHiddenColIdsForScreenMiniApps,
  updateQuickFilterColumnsForScreenCloud,
  deleteImportedRowsForMiniAppCloudFunction,
  getHeaderFieldMappingForElasticSearch,
  getHeaderDataForQuickFilterForScreen,
  getUpdatedScreenConfigurationForKanban,
  importExcelForMiniAppCloudFunction,
  addColumnCF,
  deleteColumnCF,
  editColumnCF,
  searchInReportMeta,
  moveColumnsCF,
  sortDocByCustomColumnIdCloudFunction,
  getMappedVal,
  addEditMultipleRows,
  deleteRowsDateRecurring,
  mapColumnHeaderDataWithColumnId,
  processImportExcelDocFirestore,
  generatePDF,
  getSortedMiniAppsScreensWithGroups,
  getSortedSectionedHeaderDataAsArray,
  getSectionHeaderDataAsObject,
  getOrderedHeaderDataBasedOnSections,
  getDocDataFromMiniAppState,
  generateGmailAuthURL,
  getMiniAppEmailsObjects,
  deleteIntegrationForMiniApp,
  setAutomationIdInIntegrationData,
  deleteAutomationIdFromIntegrationData,
  getIntegrationData,
  getHeaderDataForMiniAppRowEditUsingRedux,
  createImportTemplateCloudFunction,
  onDateChangeInCalendarView,
  fetchRowsFromElastic,
  addFileToTemplateList,
  deleteRowV2CF,
  getUpdatedHeaderDataAndHeaderDataAsObj,
  getDataObjForaddEditScreen,
  generateOtpCF,
  verifyOtpCF,
  getAllFilesContentAsZip,
  getDataFromEndpoint,
  createExportTemplateCloudFunction,
  exportOrgInfoDataCloudFunction,
};
